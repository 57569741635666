import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Portal from '@material-ui/core/Portal'

import { connect } from 'react-redux'
import compose from 'recompose/compose'

import Checkbox from '@material-ui/core/Checkbox'
import { updateWaiverSettings } from '../actions'
import moment from 'moment'

import '../styles/index.scss'
import '../styles/settings.scss'

const styles = theme => (null)

const mapStateToProps = ({ currentUser }) => ({
  currentGroup: currentUser.groups[0],
  currentUser
})

const mapDispatchToProps = {
  updateWaiverSettings,
}

class SettingsWaivers extends Component {
  state = {
    isRiderWaiverRequired: true,
    acceptWaiverTerms: false,
    showModal: false
  }

  componentDidMount = async () => {
    let { currentGroup } = this.props
    if (!currentGroup.isRiderWaiverRequired) {
      this.setState({
        isLocked: true,
        isRiderWaiverRequired: false
      })
    }
    else {
      this.setState({
        isRiderWaiverRequired: currentGroup.isRiderWaiverRequired
      })
    }
  }

  updateWaiverSettings = async () => {
    let group = { ...this.props.currentGroup }
    group.isRiderWaiverRequired = this.state.isRiderWaiverRequired
    group.acceptWaiverTermsAt = new Date()
    group.acceptWaiverTermsBy = this.props.currentUser._id

    await this.props.updateWaiverSettings(group)
    this.setState({
      isLocked: true,
      acceptWaiverTermsBy: this.props.currentUser,
      acceptWaiverTermsAt: new Date()
    })
  }

  render () {
    const { theme } = this.props
    const { showModal, isLocked, isRiderWaiverRequired, acceptWaiverTerms } = this.state

    return (
      <div className="page settingsWaivers">
        {
          showModal &&
          <Portal container={document.body}>
            <div
              className="modal confirm"
              style={{ backgroundColor: theme.palette.primary.fade }}
              onClick={() => {
                this.setState({ showModal: false })
              }}>

              <p style={{ textAlign: 'left' }}>I understand and accept, and hold AllCore360° harmless, any liability associated with not collecting the AllCore360° Liability Waiver electronically, and am solely responsible for obtaining and retaining original or digital copies of all AllCore360° riders since the date of installation in my facility.</p>

              <footer style={{ position: 'relative' }} className="flexRow">
                <Button
                  onClick={() => this.setState({ showModal: false })}
                  variant="contained"
                  style={theme.button.white}
                  className="button">
                Cancel
                </Button>
                <Button
                  onClick={() => this.setState({ showModal: false, acceptWaiverTerms: true })}
                  variant="contained"
                  style={theme.button.primary}
                  className="button">
                Accept Terms
                </Button>
              </footer>
            </div>
          </Portal>
        }

        <h2>Participant Release & Liability Waivers</h2>

        {
          !isLocked &&
          <div className="flexRow">
            <Checkbox
              checked={isRiderWaiverRequired}
              name='collectWaivers'
              onChange={(event) => {
                this.setState({
                  isRiderWaiverRequired: event.target.checked,
                })
              }}/>
            <p>Collect Release & Liability Waivers</p>
          </div>
        }

        {
          !this.state.isRiderWaiverRequired && !isLocked &&
          <React.Fragment>
            <div className="flexRow">
              <Checkbox
                checked={this.state.acceptWaiverTerms}
                name='collectWaivers'
                onChange={(event) => {
                  this.setState({
                    acceptWaiverTerms: event.target.checked,
                  })
                }}/>
              <p>I accept the terms for not collecting release & liability waivers</p>
            </div>
            <footer className="flexRow">
              <Button
                onClick={() => this.setState({ showModal: true })}
                variant="contained"
                style={theme.button.secondary}
                className="button">
              View Terms
              </Button>
              <Button
                onClick={this.updateWaiverSettings}
                variant="contained"
                disabled={!acceptWaiverTerms}
                style={theme.button.primary}
                className="button">
              Save Waiver Settings
              </Button>
            </footer>
          </React.Fragment>
        }

        {
          isLocked &&
          <div style={{ textAlign: 'center', lineHeight: '22px' }}>
            <p>Your group has opted to not collect Participant Release & Liability Waivers. If you would like to change this, please contact your AllCore360˚ representative.</p>
            <p style={{ marginTop: 20, fontStyle: 'italic', color: 'rgba(0,0,0,.5)' }}>
              Waiver settings last saved on {moment(this.props.acceptWaiverTermsAt).format('MM/DD/YYYY')}.
            </p>
          </div>
        }

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(SettingsWaivers)
