import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Portal from '@material-ui/core/Portal'

import { connect } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import PatientNotesHistory from './PatientNotesHistory'

import { getPatientAssessments, savePatientAssessment, deletePatientAssessment } from '../actions'
import { questionnaires } from 'common/variables'

import '../styles/patientNotes.scss'

const styles = theme => (null)

const mapStateToProps = ({ currentUser, currentPatient }, ownProps) => {
  let { assessmentResults = [] } = currentPatient

  assessmentResults.filter((result) => {
    return result._bundle === ownProps.type
  }).reverse()

  return {
    currentUser,
    currentPatient,
    assessmentResults,
  }
}

const mapDispatchToProps = {
  getPatientAssessments,
  savePatientAssessment,
  deletePatientAssessment
}

class PatientQuestionnaire extends React.Component {
  state = {
    isStarted: false,
    confirm: false,
    questionnaire: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    content: this.props.type === 'ODI' ? questionnaires.ODI : questionnaires.NDI
  }

  selectOption = (event, index) => {
    let questionnaire = this.state.questionnaire
    questionnaire[index] = event.target.value
    this.setState({ questionnaire: questionnaire })
  }

  submitAssessment = async () => {
    let scores = {}

    this.state.questionnaire.forEach((question, index) => {
      let options = this.props.type === 'ODI' ? questionnaires.ODI[index].options : questionnaires.NDI[index].options
      if (options.indexOf(question) >= 0) {
        scores[index] = options.indexOf(question)
      }
    })

    let assessment = {
      _bundle: this.props.type.toLowerCase(),
      patient: this.props.currentPatient._id,
      user: this.props.currentUser._id,
      group: this.props.currentUser.groups[0]._id,
      scores: scores,
      created: moment()
    }

    await this.props.savePatientAssessment(assessment, 'ODI Questionnaire', this.props.currentPatient)
    await this.props.getPatientAssessments(this.props.currentPatient._id)

    this.setState({
      confirm: false,
      isStarted: false,
      questionnaire: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    })

    window.scrollTo(0, 0)
  }

  render () {
    const { theme } = this.props

    return (

      <div className="page subPage patientQuestionnaire">

        <div className="inset">
          <h2>Record {this.props.type} Questionnaire</h2>
          <h3>{moment().format('MMMM Do, YYYY')}</h3>

          {
            this.state.isStarted &&
            <div className="questionnaire">

              <p>This questionnaire has been designed to give us information as to how your back or leg pain is affecting your ability to manage in everyday life.</p>
              <p>Please answer by selecting one option in each section for the statement which best applies to you.</p>

              <ol>
                {
                  this.state.content.map((question, index) => {
                    return (
                      <li key={index}>
                        <strong>{index + 1}. {question.name}</strong>
                        <FormControl component="fieldset">
                          <RadioGroup
                            value={this.state.questionnaire[index]}
                            onChange={(event) => {
                              this.selectOption(event, index)
                            }}>
                            {
                              question.options.map((option, index) => {
                                return (
                                  <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                                )
                              })
                            }
                          </RadioGroup>
                        </FormControl>
                      </li>
                    )
                  })
                }
              </ol>
            </div>
          }

          {
            this.state.isStarted
              ? <footer className="flexRow">
                <Button onClick={() => {
                  this.setState({ isStarted: false })
                }} variant="contained" style={theme.button.secondary} className='button'>Cancel</Button>
                <Button onClick={() => {
                  this.setState({ confirm: true })
                }} type="submit" variant="contained" style={theme.button.primary} className="button">Save</Button>
              </footer>
              : <footer className="flexRow">
                <Button onClick={() => {
                  this.setState({ isStarted: true })
                }} type="submit" variant="contained" style={theme.button.primary} className="button">Start</Button>
              </footer>
          }

        </div>

        <h2>{this.props.type} History</h2>
        <PatientNotesHistory type={this.props.type.toLowerCase()} assessmentResults={this.props.assessmentResults} content={questionnaires}/>

        {
          this.state.confirm &&
          <Portal container={document.body}>
            <div className="modal confirm" style={{ backgroundColor: theme.palette.primary.fade }}>

              <p style={{ marginBottom: 20, maxWidth: 600 }}>Are you sure you want to record this questionnaire for {this.props.currentPatient.name.given} {this.props.currentPatient.name.family}?</p>

              <div className="flexRow questionnaireConfirm">

                <ol style={{ paddingLeft: 20, paddingRight: 20 }}>
                  {
                    this.state.content.map((question, index) => {
                      let optionIndex = question.options.indexOf(this.state.questionnaire[index])

                      if (index < 5) {
                        return (
                          <li key={index} className={this.state.questionnaire[index] !== 0 ? undefined : 'empty'}>
                            <strong>{index + 1}. {question.name}</strong>
                            { this.state.questionnaire[index] !== 0 && <span>{question.options[optionIndex]}</span> }
                          </li>
                        )
                      }
                      else {
                        return null
                      }
                    })
                  }
                </ol>
                <ol style={{ paddingRight: 20 }}>
                  {
                    this.state.content.map((question, index) => {
                      let optionIndex = question.options.indexOf(this.state.questionnaire[index])

                      if (index >= 5) {
                        return (
                          <li key={index} className={this.state.questionnaire[index] !== 0 ? undefined : 'empty'}>
                            <strong>{index + 1}. {question.name}</strong>
                            { this.state.questionnaire[index] !== 0 && <span>{question.options[optionIndex]}</span> }
                          </li>
                        )
                      }
                      else {
                        return null
                      }
                    })
                  }
                </ol>
              </div>

              <footer className="flexRow">
                <Button onClick={() => this.setState({ confirm: false })} variant="contained" style={{ border: '2px solid white', color: 'white', backgroundColor: 'rgba(0,0,0,0)' }} className='button'>Cancel</Button>
                <Button onClick={() => this.submitAssessment()} type="submit" variant="contained" style={theme.button.primary} className="button">Save</Button>
              </footer>

            </div>
          </Portal>
        }

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientQuestionnaire)
