import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'

import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import NumericInput from 'common/components/NumericInput'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import TextField from '@material-ui/core/TextField'
import '../styles/rideConfig.scss'

import moment from 'moment'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  summary: {
    margin: 0
  },
  heading: {
    fontSize: 18,
    color: theme.palette.primary.main,
    flex: 1,
    alignItems: 'center'
  },
})

class ListExpandableItemAC360 extends React.Component {
  render () {
    const { theme, locations, classes, index, session, coreScore, sets } = this.props
    const { personalBest, editing, confirmDelete, editingItem, expanded, menu } = this.props.state

    return (
      <li key={index} className={'expandableItem allcore360'}>
        <ExpansionPanel classes={{ root: classes.root }} expanded={expanded === index} onChange={this.props.handleChange(index)}>
          <ExpansionPanelSummary className={classes.summary}>
            <Typography className={classes.heading} style={{ display: 'flex' }}>
              <i className={expanded === index ? 'caret down' : 'caret'} />
              <strong>{moment(session.startTime).format('MMMM Do, YYYY')}</strong>
              <span style={{ width: 60, marginRight: 20 }}> {moment(session.startTime).format('h:mm A')} </span>

              <span className="sessionTotals angle">
                <i className="icon icon-angle"/>
                <span className="label">Angle</span>
                <span className="total">{session.averageAngle}˚</span>
              </span>

              <span className="sessionTotals spins">
                <i className="icon icon-spins"/>
                <span className="label">Spins</span>
                <span className="total">{session.totalSpins}</span>
              </span>
              {
                coreScore &&
                <span className="sessionTotals coreScore">
                  {
                    coreScore === personalBest
                      ? <i className="icon trophy"/>
                      : <i className="icon star"/>
                  }
                  <span className="total" style={{ color: theme.palette.secondary.main }}>{coreScore}</span>
                  {
                    coreScore === personalBest && <em style={{ color: theme.palette.secondary.main }}>Personal Best</em>
                  }
                </span>
              }
            </Typography>

          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 20 }}>

            <span onClick={() => this.props.expandMenu(index)} className={classNames('icon', 'listMenu', { 'show': expanded === index })} />
            <div className={classNames('listActions', { 'active': menu === index })}>
              <span className="icon remove" onClick={() => this.props.confirmDelete(index)}/>
              <span onClick={() => this.props.editItem(session, index)} className="icon edit"/>
            </div>

            {
              locations && locations.length > 0 &&
              <div className={classNames('locationSelectWrapper session', editing === index && 'editing')}>
                <Select
                  className="locationSelect"
                  value={this.props.session.location}
                  onChange={(event) => this.props.changeLocation(event)}>
                  {
                    locations && locations.map((location, index) => {
                      return (
                        <MenuItem key={index} value={location._id}>{location.title}</MenuItem>
                      )
                    })
                  }
                </Select>
              </div>
            }

            <div className="rideConfig">
              {
                editing === index &&
              <div className="flexRow dateEditWrapper">
                <i className="icon calendar" style={{ marginRight: 10, position: 'relative', top: '5px' }}></i>
                <TextField
                  type="datetime-local"
                  classes={{ root: 'form-group' }}
                  onChange={(event) => this.props.updateParameter('date', event)}
                  value={moment(editingItem.startTime).format('YYYY-MM-DDTHH:mm')}
                />
              </div>
              }
              <ol>
                {
                  sets.map((set, setIndex) => {
                    if (set && editing === index) {
                      return (
                        <li key={setIndex} className="editing">

                          <div className="set">
                            <div className="parameter parameter-input">

                              <NumericInput index={setIndex} onChange={(value) => this.props.updateParameter('spins', value, setIndex)} value={set.spins} />

                              <label htmlFor="spins">
                              Spins at
                              </label>
                            </div>
                            <div className="parameter parameter-input">

                              <NumericInput index={setIndex} onChange={(value) => this.props.updateParameter('degrees', value, setIndex)} value={set.degrees} />

                              <label htmlFor="degrees">
                              Degrees
                              </label>
                            </div>
                            <label className="parameter parameter-direction">
                              {
                                set.direction && set.direction === 'CW' &&
                                  <input
                                    name="direction"
                                    type="checkbox"
                                    defaultChecked={true}
                                    onChange={(value) => this.props.updateParameter('direction', value, setIndex)}
                                  />
                              }
                              {
                                set.direction && set.direction === 'CCW' &&
                                <input
                                  name="direction"
                                  type="checkbox"
                                  defaultChecked={false}
                                  onChange={(value) => this.props.updateParameter('direction', value, setIndex)}
                                />
                              }
                              <i className={`direction ${set.direction.toLowerCase()}`}></i>
                              <div className={`direction-label direction-${set.direction.toLowerCase()}-label`}>
                                <div>Counter</div>
                                <div>Clockwise</div>
                              </div>
                            </label>
                          </div>
                        </li>

                      )
                    }
                    else if (set) {
                      return (
                        <li key={setIndex}>
                          <div className="set">
                            <div className="parameter parameter-input parameter-spins">

                              <NumericInput index={setIndex} value={set.spins} />

                              <label htmlFor="spins">
                              Spins at
                              </label>
                            </div>
                            <div className="parameter parameter-input parameter-degrees">
                              <NumericInput index={setIndex} value={set.degrees} />

                              <label htmlFor="degrees">
                              Degrees
                              </label>
                            </div>
                            <label className="parameter parameter-input parameter-direction">
                              <input
                                name="direction"
                                type="checkbox"
                                defaultChecked={set.direction === 'CW'}
                              />
                              { set.direction && <i className={`direction ${set.direction.toLowerCase()}`}></i> }
                              {
                                set.direction &&
                                <div className={`direction-label direction-${set.direction.toLowerCase()}-label`}>
                                  <div>Counter</div>
                                  <div>Clockwise</div>
                                </div>
                              }
                            </label>
                          </div>
                        </li>
                      )
                    }
                    else {
                      return true
                    }
                  })
                }
              </ol>
            </div>

            {
              editing === index
                ? <footer className="flexRow">
                  <Button onClick={this.props.cancelEdit} variant="contained" style={this.props.theme.button.secondary} className='button'>Cancel</Button>
                  <Button onClick={() => this.props.submitItem('allcore360')} variant="contained" style={this.props.theme.button.primary} className="button">Save Session</Button>
                </footer>
                : <footer className="flexRow">
                  <Button variant="contained" style={this.props.theme.button.primary} className='button' onClick={() => this.props.runRideAgain(session)}>Run Again</Button>
                </footer>
            }
            {
              confirmDelete === index &&
            <div className="deleteModal">
              Are you sure you want to delete this session?
              <footer className="flexRow">
                <Button onClick={this.props.cancelDelete} variant="contained" style={this.props.theme.button.secondary} className='button'>Cancel</Button>
                <Button onClick={() => this.props.deleteItem(session, 'allcore360')} variant="contained" style={this.props.theme.button.danger} className="button">Delete Session</Button>
              </footer>
            </div>
            }
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </li>

    )
  }
}

export default compose(withStyles(styles, { withTheme: true }))(ListExpandableItemAC360)
