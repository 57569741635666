import { Component } from 'react'

class Login extends Component {
  componentDidMount () {
    let url = `${process.env.REACT_APP_LOGIN_URL}user/login?destination=${window.location}`
    window.location.href = url
  }
  render () {
    return false
  }
}

export default Login
