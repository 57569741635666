import React from 'react'

class Loading extends React.Component {
  render () {
    return (
      <div className="loading">
        <div className="circle"/>
        <div className="circle secondary"/>
      </div>
    )
  }
}

export default Loading
