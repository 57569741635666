import React from 'react'
import { compose } from 'redux'
import moment from 'moment'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Portal from '@material-ui/core/Portal'

import ParameterInput from 'common/components/ParameterInput'
import Loading from './Loading'
import update from 'immutability-helper'

class RideConfig extends React.Component {
  state = {
    isLoading: false,
    modalConfirm: false,
  }

  addSet = () => {
    let lastSet = this.props.ride.sets[this.props.ride.sets.length - 1]

    // Add a duplicate of the previous set to the ride
    let newRide = update(this.props.ride, {
      sets: {
        $push: [{
          spins: lastSet.spins,
          degrees: lastSet.degrees,
          direction: lastSet.direction,
          date: moment().format()
        }]
      }
    })

    // Scroll the page down
    setTimeout(() => {
      window.scrollTo(0, window.pageYOffset + 1200)
    }, 500)

    // Pass the newly changed ride to the StartRide component
    this.props.onChange(newRide)
  }

  removeSet (index) {
    let newRide = update(this.props.ride, {
      sets: {
        $splice: [[index, 1]]
      }
    })

    this.props.onChange(newRide)
  }

  // This directly sets a parameter
  updateParameter = (index, parameter, value) => {
    let newRide = update(this.props.ride, {
      sets: {
        [index]: {
          [parameter]: {
            $set: value
          }
        }
      }
    })
    this.props.onChange(newRide)
  }

  changeDirection = (event, index) => {
    let newRide = update(this.props.ride, {
      sets: {
        [index]: {
          direction: {
            $set: event.target.checked ? 'CW' : 'CCW'
          }
        }
      }
    })

    this.props.onChange(newRide)
  }

  openInControl () {
    const { currentPatient, ride } = this.props
    window.location = process.env.REACT_APP_CONTROL_URL + '?patient=' + currentPatient.patientID + '&rideData=' + JSON.stringify({ sets: ride.sets })
  }

  async submit () {
    this.setState({ isLoading: true })
    await this.props.submitRide(this.props.ride)
    this.setState({ modalConfirm: false, isLoading: false })
  }

  render () {
    const { theme, isVisible, ride, personalBest, projectedCoreScore } = this.props
    const { modalConfirm, isLoading } = this.state

    return (
      <div className="rideConfig">
        <ol>
          {
            ride.sets.map((set, index) => {
              return (
                <li key={index}>
                  <div className="set">
                    <ParameterInput
                      label="Spins at"
                      value={set.spins}
                      onChange={value => this.updateParameter(index, 'spins', value)}
                      min={1}
                      max={10}
                    />
                    <ParameterInput
                      label="Degrees"
                      value={set.degrees}
                      onChange={value => this.updateParameter(index, 'degrees', value)}
                      min={0}
                      max={90}
                    />
                    <label className="parameter parameter-direction">
                      <input
                        name="direction"
                        type="checkbox"
                        checked={set.direction === 'CW'}
                        onChange={(event) => this.changeDirection(event, index)}
                      />
                      <i className={`direction ${set.direction.toLowerCase()}`}></i>
                      <div className={`direction-label direction-${set.direction.toLowerCase()}-label`}>
                        <div>Counter</div>
                        <div>Clockwise</div>
                      </div>
                    </label>
                    <div className={classNames(ride.sets.length > 1 ? 'delete active' : 'delete')} onClick={() => this.removeSet(index)}></div>
                  </div>
                </li>
              )
            })
          }
        </ol>

        <div className="flexRow addSet">
          <Button onClick={() => this.addSet()} variant="contained" style={theme.button.secondary} className='button'>Add Set</Button>
        </div>

        {
          isVisible && !modalConfirm &&
          <Portal container={document.body}>
            <footer className="startRideAction">

              <div style={{ color: theme.palette.primary.main }} className="coreScore">
                Projected Core Score
                <i className="icon star"/>
                <span className="score" style={{ color: theme.palette.secondary.main }}>
                  {projectedCoreScore}
                </span>
                {
                  personalBest < projectedCoreScore &&
                  <span style={{ marginLeft: 10 }}>New Personal Best</span>
                }
              </div>

              <div className="flexRow">
                <Button onClick={() => this.openInControl()} variant="contained" style={theme.button.primary} className="button">Run & Record</Button>
                <Button onClick={() => this.setState({ modalConfirm: true })} variant="contained" style={theme.button.primary} className="button">Record Only</Button>
              </div>

            </footer>
          </Portal>
        }

        {
          isVisible && modalConfirm &&
          <Portal container={document.body}>
            <div className="modal confirm" style={{ backgroundColor: theme.palette.primary.fade }}>

              {
                !isLoading &&
                <div>
                  <p>Are you sure you want to record this ride for {this.props.currentPatient.name.given} {this.props.currentPatient.name.family}?</p>
                  <ol className="rideSummary">
                    {
                      ride.sets.map((set, index) => {
                        let direction
                        if (set.direction === 'CW') {
                          direction = 'Clockwise'
                        }
                        else {
                          direction = 'Counter Clockwise'
                        }

                        return (
                          <li key={index}>
                            <div className="parameter">
                              <span className="number">{set.spins}</span>
                              {set.spins > 1 ? 'Spins at' : 'Spin at'}
                            </div>
                            <div className="parameter">
                              <span className="number">{set.degrees}</span>Degrees
                            </div>
                            <div className="parameter">
                              <i className={`direction white ${set.direction.toLowerCase()}`}></i>
                              <div className="setDirection">
                                {direction}
                              </div>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ol>

                  <div style={{ color: 'white' }} className="coreScore">
                    Projected Core Score
                    <i className="icon star"/>
                    <span className="score" style={{ color: theme.palette.secondary.main }}>{projectedCoreScore}</span>
                    {
                      personalBest < projectedCoreScore &&
                      <span style={{ marginLeft: 10 }}>New Personal Best</span>
                    }
                  </div>
                  <footer className="flexRow">
                    <Button onClick={() => this.setState({ modalConfirm: false })} variant="contained" style={{ border: '2px solid white', color: 'white', backgroundColor: 'rgba(0,0,0,0)' }} className='button'>Cancel</Button>
                    <Button onClick={() => this.submit()} variant="contained" style={theme.button.primary} className="button">Record Ride</Button>
                  </footer>
                </div>
              }
              {
                isLoading &&
                <div>
                  <Loading />
                  <h3 className="loadingText">Recording AllCore360˚ ride...</h3>
                </div>
              }
            </div>
          </Portal>
        }

      </div>
    )
  }
}

export default compose(withStyles(null, { withTheme: true }))(RideConfig)
