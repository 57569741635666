import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Loading from './Loading'

import moment from 'moment'

const styles = theme => (null)

class TotalRidesContainer extends React.Component {
  render () {
    const { theme } = this.props

    return (
      <div className="totalRidesContainer flexRow">

        <div className="rides flexRow" style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
          <div>
            <span>Total Rides for</span>
            {
              this.props.dateRange === 'thisMonth' &&
              <strong>{moment().startOf('month').format('MMMM YYYY')}</strong>
            }
            {
              this.props.dateRange === 'thisQuarter' &&
              <strong>This Quarter</strong>
            }
            {
              this.props.dateRange === 'thisYear' &&
              <strong>{moment().startOf('year').format('YYYY')}</strong>
            }
            {
              this.props.dateRange === 'dateRange' &&
              <strong>{moment(this.props.dateFrom).format('M/D/YYYY')} - {moment(this.props.dateTo).format('M/D/YYYY')}</strong>
            }
          </div>
          <div className="rideTotal">
            <div style={{ color: theme.palette.secondary.main }}>
              <strong>
                { this.props.loading
                  ? <Loading/>
                  : this.props.numRides && this.props.numRides.toLocaleString()
                }
              </strong>
            </div>
          </div>
        </div>

        <div className="owed flexRow" style={{ border: '1px solid ' + theme.palette.primary.main }}>
          <div>Total Owed</div>
          <div className="owedTotal">

            {
              this.props.loading
                ? <Loading/>
                : <div>
                  <span>$</span>
                  {this.props.totalCost && this.props.totalCost.toLocaleString()}
                </div>
            }

          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(TotalRidesContainer)
