import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => (null)

class AssessmentChart extends React.Component {
  render () {
    const { theme } = this.props

    return (
      <span className="donutChart" style={{ backgroundColor: theme.palette.secondary.light }}>
        <svg viewBox="0 0 36 36">
          <path
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke={theme.palette.secondary.main}
            strokeWidth="4"
            strokeDasharray={`${this.props.score * 10}, 100`}
          />
        </svg>

      </span>

    )
  }
}

export default withStyles(styles, { withTheme: true })(AssessmentChart)
