import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import SwipeableViews from 'react-swipeable-views'

import { connect } from 'react-redux'
import compose from 'recompose/compose'

import PatientAssessments from './PatientAssessments'
import PatientNotes from './PatientNotes'
import PatientQuestionnaire from './PatientQuestionnaire'
import classNames from 'classnames'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { updatePatient, clearTargetTab } from '../actions'
import '../styles/patientNotes.scss'

const styles = theme => (null)

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
    currentPatient: state.currentPatient,
    targetTab: state.targetTab
  }
}

const mapDispatchToProps = {
  updatePatient,
  clearTargetTab
}

class PatientNotesLayout extends React.Component {
  state = {
    currentTab: 0,
  }

  changeTab = (event, tab) => {
    this.setState({ currentTab: tab })
  }

  onPageSwipe = index => {
    window.scrollTo(0, 0)
    this.setState({ currentTab: index })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isVisible !== this.props.isVisible) {
      if (this.props.targetTab) {
        if (this.props.targetTab === 'assessment') {
          this.setState({ currentTab: 0 })
        }
        else if (this.props.targetTab === 'note') {
          this.setState({ currentTab: 1 })
        }
        else if (this.props.targetTab === 'questionnaire') {
          this.setState({ currentTab: 2 })
        }
      }
      this.props.clearTargetTab()
    }
  }

  render () {
    const { isVisible, theme } = this.props

    return (
      <div className={classNames('page patientNotes', !isVisible && 'shrink')}>

        <div style={{ padding: '0 15px' }}>
          <Tabs
            variant="fullWidth"
            value={this.state.currentTab}
            onChange={this.changeTab}
            classes={{ root: 'tabsSecondary', indicator: 'tabsIndicator' }}
            style={{ border: '1px solid ' + theme.palette.secondary.main }}>
            <Tab style={{ borderRight: '1px solid ' + theme.palette.secondary.main }} disableRipple value={0} key={0} label="Assessments" />
            <Tab style={{ borderRight: '1px solid ' + theme.palette.secondary.main }} disableRipple value={1} key={1} label="Notes" />
            <Tab style={{ borderRight: '1px solid ' + theme.palette.secondary.main }} disableRipple value={2} key={2} label="ODI" />
            <Tab disableRipple value={3} key={3} label="NDI" />
          </Tabs>
        </div>

        <SwipeableViews
          animateHeight={false}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.currentTab}
          onChangeIndex={this.onPageSwipe}>

          <PatientAssessments />
          <PatientNotes />
          <PatientQuestionnaire type='ODI' />
          <PatientQuestionnaire type='NDI' />

        </SwipeableViews>

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientNotesLayout)
