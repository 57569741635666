import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers'

// Middleware
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import theme from 'common/client/theme'

import App from './components/App'

let middleware = [ thunkMiddleware ]

if (process.env.DEBUG) {
  const logger = createLogger()
  middleware = [ ...middleware, logger ]
}

let store = createStore(
  reducers,
  applyMiddleware(...middleware)
)

let muiTheme = createMuiTheme(theme)

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme}>
    <Provider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <App />
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
)
