import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Navbar from './Navbar'
import StartRide from './StartRide'
import AllCore360 from './AllCore360'
import PatientProfile from './PatientProfile'
import PatientNotesLayout from './PatientNotesLayout'
import PatientOverview from './PatientOverview'

import { getPatient, updateTabHistory } from '../actions'

import compose from 'recompose/compose'

import '../styles/dashboard.scss'

const styles = theme => (null)

const mapStateToProps = (state, ownProps) => {
  return {
    tabHistory: state.tabHistory,
    currentUser: state.currentUser,
    patients: state.patients,
    currentPatient: state.currentPatient
  }
}

const mapDispatchToProps = {
  getPatient,
  updateTabHistory
}

const tabNames = [
  'overview',
  'allcore360',
  'run-ride',
  'notes',
  'profile',
]

class PatientSingleLayout extends React.Component {
  state = {
    repeatedRide: null
  }

  componentDidMount () {
    this.props.getPatient(this.props.match.params.id)
  }

  handleTabChange = (event, tabName) => {
    this.changeTab(tabName)
  }

  onSwipe = tabIndex => {
    this.changeTab(tabNames[tabIndex])
  }

  changeTab = (tabName) => {
    window.scrollTo(0, 0)
    this.props.history.push('/rider/' + this.props.currentPatient._id + '/' + tabName)
  }

  runRideAgain = (session) => {
    this.props.history.push('/rider/' + this.props.currentPatient._id + '/run-ride')
    this.setState({ repeatedRide: session })
  }

  render () {
    const { currentPatient } = this.props
    let navbarLabel
    if (currentPatient && currentPatient.name) {
      let patientName = currentPatient.name
      navbarLabel = patientName.given + ' ' + patientName.family
    }

    let userAgent
    if (navigator.userAgent.indexOf('Firefox') >= 0) {
      userAgent = 'firefox'
    }

    return (
      <div>
        <AppBar position="fixed" className="appBar">

          <Navbar title={navbarLabel}/>

          <div className="navTabsWrapper">
            <Tabs
              variant="fullWidth"
              value={this.props.tab}
              onChange={this.handleTabChange}
              className="expandable"
              classes={{ root: 'navTabs ' + userAgent, indicator: 'tabsIndicator' }}>
              <Tab disableRipple label="Overview" value="overview"/>
              <Tab disableRipple label="AllCore360˚" value="allcore360"/>
              <Tab disableRipple label="Run Ride" value="run-ride"/>
              <Tab disableRipple label="Notes" value="notes"/>
              <Tab disableRipple label="Profile" value="profile"/>

            </Tabs>
          </div>
        </AppBar>

        {
          currentPatient && currentPatient.name &&
          <SwipeableViews
            animateHeight={false}
            index={this.props.tabIndex}
            onChangeIndex={this.onSwipe}>

            <PatientOverview history={this.props.history} patientId={currentPatient._id} index={0} isVisible={this.props.tabIndex === 0} changeTab={this.handleTabChange}/>
            <AllCore360 runRideAgain={this.runRideAgain} patientId={currentPatient._id} index={1} isVisible={this.props.tabIndex === 1}/>
            <StartRide
              patientId={currentPatient._id}
              index={2}
              isVisible={this.props.tabIndex === 2}
              repeatedRide={this.state.repeatedRide}
              goHome={() => {
                this.setState({ page: 0 })
              }}
            />
            <PatientNotesLayout patientId={currentPatient._id} index={3} isVisible={this.props.tabIndex === 3} />
            <PatientProfile patientId={currentPatient._id} index={4} isVisible={this.props.tabIndex === 4} />

          </SwipeableViews>
        }
      </div>
    )
  }
}

PatientSingleLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientSingleLayout)
