import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'

import { withStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import Navbar from './Navbar'
import PatientTable from './PatientTable'
import { getAllGroups, updateTabHistory } from '../actions'

import '../styles/index.scss'
import '../styles/patients.scss'

const styles = theme => (null)

const mapStateToProps = (state, ownProps) => {
  return {
    tabHistory: state.tabHistory,
    currentUser: state.currentUser,
    groups: state.currentUser.groups,
    patients: state.patients
  }
}

const mapDispatchToProps = {
  updateTabHistory,
  getAllGroups
}

class PatientLayout extends React.Component {
  state = {
    page: 0,
  }

  handleTabChange = (event, page) => {
    window.scrollTo(0, 0)

    let pageIndex
    if (page.props) {
      pageIndex = page.props.value
    }
    else {
      pageIndex = page
    }

    this.setState({ page: pageIndex })
    this.props.updateTabHistory('riders', pageIndex)
  }

  onSwipe = index => {
    window.scrollTo(0, 0)
    this.setState({ page: index })
    this.props.updateTabHistory('riders', index)
  }

  async componentDidMount () {
    await this.props.getAllGroups()

    // Get the previous tab we were on from the store
    let history = this.props.tabHistory.find((page) => {
      return page.page === 'riders'
    })
    this.setState({ page: history ? history.currentTab : 0 })
  }

  render () {
    const { theme } = this.props
    let tabsStyle, userAgent

    if (this.props.currentUser.groups.length < 5) {
      tabsStyle = { 'left': this.state.page * -15, marginRight: this.state.page * -15 }
    }
    if (navigator.userAgent.indexOf('Firefox') >= 0) {
      userAgent = 'firefox'
    }

    return (
      <div>
        <AppBar position="fixed" className="appBar">

          <Navbar title="Riders" />

          {
            this.props.currentUser.groups.length >= 5 &&
            <div className="groupSelectWrapper">
              <Select
                className="groupSelect"
                value={this.state.page}
                onChange={this.handleTabChange}>
                {
                  this.props.currentUser.groups.map((group, index) => {
                    return (
                      <MenuItem key={index} value={index}>{group.title}</MenuItem>
                    )
                  })
                }
              </Select>
            </div>
          }

          {
            this.props.currentUser.groups.length < 5 &&
            <Tabs
              variant="fullWidth"
              value={this.state.page}
              onChange={this.handleTabChange}
              className={tabsStyle ? '' : 'tabless'}
              classes={{ root: 'navTabs ' + userAgent, indicator: 'tabsIndicator' }}
              style={tabsStyle}>
              {
                this.props.currentUser.groups.map((group, index) => {
                  return (
                    <Tab disableRipple key={index} label={group.title} gid={group._id}/>
                  )
                })
              }
            </Tabs>
          }

        </AppBar>

        <SwipeableViews
          animateHeight={false}
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.page}
          onChangeIndex={this.onSwipe}>
          {
            this.props.currentUser.groups.map((group, index) => {
              return (
                <PatientTable history={this.props.history} key={index} index={index} group={group} page={this.state.page} />
              )
            })
          }
        </SwipeableViews>
      </div>
    )
  }
}

PatientLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientLayout)
