import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles'

const CustomTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.primary.contrastText,
    background: '#a5a5a5',
    fontSize: 18,
    borderTop: 'none',
    borderBottom: 'none',
  },
  body: {
    border: 'none',
    textAlign: 'left',
    fontSize: 18
  },
}))(TableCell)

export default CustomTableCell
