import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'
import compose from 'recompose/compose'

import SettingsLocationListItem from './SettingsLocationListItem'

import '../styles/index.scss'
import '../styles/settings.scss'

const styles = theme => (null)

const mapStateToProps = ({ currentUser }) => ({ currentUser })
const mapDispatchToProps = {}

class SettingsLocations extends Component {
  render () {
    return (
      <div className="page settingsLocations">
        <ul className="groupsList">
          {
            this.props.currentUser.groups && this.props.currentUser.groups.map((group, index) => {
              if (group.locations) {
                return (
                  <li key={group._id}>
                    <h2>{group.title}</h2>
                    <ol className="listExpandable">
                      {
                        group.locations.map((location) => (
                          <SettingsLocationListItem
                            key={location._id}
                            title={location.title}
                            className="location"
                            panelGroup="location"
                            panelKey={location._id}
                            location={location}
                          />
                        ))
                      }
                    </ol>
                  </li>
                )
              }
              return true
            })
          }
        </ul>
      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(SettingsLocations)
