import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import classNames from 'classnames'

import Navbar from './Navbar'
import LeaderboardSingle from './LeaderboardSingle'

import TabsDateRange from './TabsDateRange'
import Switch from '@material-ui/core/Switch'

import {
  changeLeaderboardTimePeriod,
  changeLeaderboardReportType,
  updateTabHistory,
} from '../actions'

import '../styles/index.scss'
import '../styles/leaderboards.scss'

const styles = theme => (null)

const mapStateToProps = ({ tabHistory, currentUser, leaderboard: { timePeriod, reportType } }, ownProps) => {
  let roles = currentUser.roles
  let groups = currentUser.groups

  let props = {
    tabHistory,
    roles,
    groups,
    timePeriod,
    reportType,
  }

  if (roles.includes('administrator')) {
    let leaderboardProps = [
      {
        key: 'group-all',
        group: 'all',
        title: 'All Groups',
        role: 'administrator'
      },
      ...groups.map(group => ({
        key: `group-${group._id}`,
        group: group._id,
        title: group.title,
        role: 'administrator'
      }))
    ]
    props = { ...props, leaderboardProps }
  }
  else if (roles.includes('therapist')) {
    let [ currentGroup ] = groups
    let leaderboardProps = []
    if (currentGroup.locations) {
      leaderboardProps = [
        {
          key: 'location-all',
          group: currentGroup._id,
          location: 'all',
          title: 'All Locations',
          role: 'therapist'
        },
        ...currentGroup.locations.map(location => ({
          key: `location-${location._id}`,
          group: currentGroup._id,
          location: location._id,
          title: location.title,
          role: 'therapist'
        }))
      ]
    }
    props = { ...props, currentGroup, leaderboardProps }
  }

  return props
}

const mapDispatchToProps = {
  updateTabHistory,
  changeLeaderboardTimePeriod,
  changeLeaderboardReportType,
}

class LeaderboardLayout extends React.Component {
  constructor (props) {
    super(props)

    if (props.roles.includes('administrator')) {
      this.state = {
        currentTab: 'group-all'
      }
    }
    else if (props.roles.includes('therapist')) {
      // @todo get the current Group
      this.state = {
        currentTab: 'location-all'
      }
    }
  }

  componentDidMount () {
    // Get the previous tab we were on from the store
    let history = this.props.tabHistory.find((page) => {
      return page.page === 'leaderboard'
    })

    if (history) {
      this.setState({ currentTab: history.currentTab })
    }
  }

  changeTimePeriod = (timePeriod) => {
    this.props.changeLeaderboardTimePeriod(timePeriod)
  }

  changeReportType = (event, checked) => {
    this.props.changeLeaderboardReportType(checked ? 'total' : 'single')
  }

  changeTab = (event, value) => {
    window.scrollTo(0, 0)

    let currentTab = value
    if (value.key) {
      currentTab = value.key
    }
    else if (value.props) {
      currentTab = value.props.value
    }

    this.setState({ currentTab: currentTab })
    this.props.updateTabHistory('leaderboard', currentTab)
  }

  onSwipe = (index, indexLatest, meta) => {
    window.scrollTo(0, 0)
    let leaderboardProp = this.props.leaderboardProps[index]

    this.setState({ currentTab: leaderboardProp.key })
    this.props.updateTabHistory('leaderboard', leaderboardProp.key)
  }

  render () {
    // really? it's 2019
    let userAgent = navigator.userAgent.indexOf('Firefox') >= 0 ? 'firefox' : ''

    let selectProps = {
      className: 'groupSelect',
      onChange: this.changeTab,
      value: this.state.currentTab,
    }
    let tabsProps = {
      variant: 'fullWidth',
      classes: {
        root: classNames('navTabs', userAgent),
        indicator: 'tabsIndicator',
      },
    }

    let nav

    if (this.props.roles.includes('administrator')) {
      // If we're an administrator and have more than 5 groups, show the select dropdown
      if (this.props.groups.length >= 5) {
        nav = <div className="groupSelectWrapper">
          <Select { ...selectProps }>
            <MenuItem value='group-all'>All Groups</MenuItem>
            {
              this.props.groups.map(group => {
                return (
                  <MenuItem key={`group-${group._id}`} value={`group-${group._id}`}>{group.title}</MenuItem>
                )
              })
            }
          </Select>
        </div>
      }
      // If we're an administrator and have less than 5 groups
      else {
        nav = <Tabs { ...tabsProps } value={this.state.currentTab} onChange={this.changeTab}>
          <Tab disableRipple value='group-all' label="All Groups" />
          {
            this.props.groups.map(group => {
              return (
                <Tab disableRipple key={`group-${group._id}`} label={group.title} value={`group-${group._id}`} />
              )
            })
          }
        </Tabs>
      }
    }
    // If we're a therapist
    else if (this.props.roles.includes('therapist')) {
      let locations = this.props.currentGroup.locations
      if (locations) {
        nav = <Tabs { ...tabsProps } value={locations.length > 1 ? this.state.currentTab : `location-${locations[0]._id}`} onChange={this.changeTab}>
          {
            locations.length > 1 &&
            <Tab disableRipple value='location-all' label="All Locations"/>
          }
          {
            locations.map(location => (
              <Tab disableRipple key={`location-${location._id}`} label={location.title} value={`location-${location._id}`}/>
            ))
          }
        </Tabs>
      }
    }

    let currentProps = this.props.leaderboardProps.find(props => {
      return props.key === this.state.currentTab
    })

    return (
      <div>
        <AppBar position="fixed" className="appBar">
          <Navbar title="Core Score Leaderboard" />
          { nav }
        </AppBar>

        <div className="page">

          { currentProps && <h2>{ currentProps.title }</h2> }

          <div className="switchWrapper flexRow">
            <span onClick={() => this.props.changeLeaderboardReportType('single')} className={this.props.reportType === 'single' ? 'active' : ''}>Single</span>
            <Switch
              className="switch"
              checked={(this.props.reportType === 'total')}
              onChange={this.changeReportType}
              value="reportType"
            />
            <span onClick={() => this.props.changeLeaderboardReportType('total')} className={this.props.reportType === 'total' ? 'active' : ''}>Total</span>
          </div>
          <TabsDateRange weekFirst={true} action={this.changeTimePeriod} activeTab={this.props.timePeriod} />

          <SwipeableViews
            index={this.props.leaderboardProps.findIndex(leaderboardProp => leaderboardProp.key === this.state.currentTab)}
            onChangeIndex={this.onSwipe}
            animateHeight={false}>

            { this.props.leaderboardProps.map(leaderboardProp => (<LeaderboardSingle {...leaderboardProp } />)) }

          </SwipeableViews>
        </div>

      </div>

    )
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(LeaderboardLayout)
