import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import expandableListItem from './ExpandableListItem'

// Components
import Button from '@material-ui/core/Button'
import LocationForm from '../forms/LocationForm'

// Actions
import { updateLocation, deleteLocation } from '../actions'

import classNames from 'classnames'

const mapStateToProps = ({ currentUser }) => {
  return {
    isAdmin: currentUser.roles && currentUser.roles.includes('administrator')
  }
}
const mapDispatchToProps = { updateLocation, deleteLocation }

class SettingsLocationListItem extends Component {
  state = {
    isMenuVisible: false,
    isEditable: false,
    isConfirmDeleteVisible: false,
  }

  toggleMenu = () => {
    this.setState({ isMenuVisible: !this.state.isMenuVisible })
  }

  showForm = () => {
    this.setState({ isEditable: true })
  }
  hideForm = () => {
    this.setState({ isEditable: false })
  }

  showConfirmDelete = (value) => {
    this.setState({ isConfirmDeleteVisible: true })
  }
  hideConfirmDelete = () => {
    this.setState({ isConfirmDeleteVisible: false })
  }

  submit = (values) => {
    this.props.updateLocation(values)
    this.hideForm()
  }

  delete = (values) => {
    this.props.deleteLocation(this.props.location)
  }

  render () {
    let { isMenuVisible, isConfirmDeleteVisible } = this.state
    let { location } = this.props

    return (
      <div>
        <LocationForm
          form={`location[${location._id}]`}
          initialValues={location}
          onSubmit={this.submit}
          onCancel={this.hideForm}
          isEditable={this.state.isEditable}
        />
        <span onClick={this.toggleMenu} className={classNames('icon', 'listMenu', { 'show': this.props.isExpanded })} />
        <div className={classNames('listActions', { 'active': isMenuVisible })}>
          {
            this.props.isAdmin &&
            <span className="icon remove" onClick={() => this.showConfirmDelete()} />
          }
          <span onClick={this.showForm} className="icon edit"/>
        </div>
        {
          isConfirmDeleteVisible &&
          <div className="deleteModal">
            Are you sure you want to delete this location?
            <footer className="flexRow">
              <Button onClick={() => this.hideConfirmDelete()} variant="contained" style={this.props.theme.button.secondary} className='button'>Cancel</Button>
              <Button onClick={this.delete} variant="contained" style={this.props.theme.button.danger} className="button">Delete Location</Button>
            </footer>
          </div>
        }
      </div>
    )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps), expandableListItem)(SettingsLocationListItem)
