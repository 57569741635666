// Authentication
import feathers, { authentication, socketio } from '@feathersjs/client'
import io from 'socket.io-client'

import { CookieStorage } from 'cookie-storage'

import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'

const app = feathers()

// If this is a production (build) environment, use the API_URL
const socket = io((process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_URL) || '')
app.configure(socketio(socket, { timeout: 20000 }))

const cookieStorage = new CookieStorage({
  path: '/',
  domain: process.env.REACT_APP_COOKIE_DOMAIN || ''
})

app.configure(authentication({
  entity: 'user',
  service: 'user',
  storage: cookieStorage,
}))

app.on('authenticated', async (token) => {
  let payload = await app.passport.verifyJWT(token.accessToken)

  // Refresh the token 5 minutes before it expires
  let millis = (payload.exp * 1000) - (new Date().getTime()) - (5 * 60 * 1000)
  let timeout = setTimeout(() => app.authenticate(), millis)

  // Clear the timeout if the user logs out
  // @todo should this only be called once?
  app.on('logout', () => clearTimeout(timeout))
})

if (process.env.NODE_ENV === 'production') {
  // Google analytics for production only
  if (process.env.REACT_APP_GA_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID)
  }
  // Hotjar for production only
  if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_SNIPPET_VERSION) {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION)
  }
}

export default app
