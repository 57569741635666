import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import Portal from '@material-ui/core/Portal'
import { Button } from '@material-ui/core'
import '../styles/index.scss'

import { createMuiTheme } from '@material-ui/core/styles'
import theme from 'common/client/theme'

let muiTheme = createMuiTheme(theme)

class Legal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      accepted: false,
      submitted: false,
    }
    this.acceptLegal = this.acceptLegal.bind(this)
  }

  handleCheckbox (event) {
    this.setState({ accepted: event.target.checked })
  }

  acceptLegal () {
    this.props.acceptLegal()
    this.setState({ submitted: true })
  }

  render () {
    const { currentUser, currentLegal } = this.props
    let accepted
    // Find out if the user has already accepted the current legal item
    if (currentUser.legal && currentUser.legal === currentLegal._id) {
      accepted = true
    }
    // If we've fetched the current legal item the current user AND they haven't already accepted/submitted
    if ((currentLegal._id && currentUser) && (!accepted && !this.state.submitted)) {
      return (
        <Portal container={document.body}>
          <div className="toc">
            <div className="inner">
              <div className="legalBody">
                <h2>Terms & Conditions</h2>
                <div dangerouslySetInnerHTML={{ __html: currentLegal.body }} />
              </div>
              <footer className="flexRow column">
                <div className="flexRow">
                  <Checkbox
                    checked={this.state.accepted}
                    name='rideComplete'
                    onChange={(event) => {
                      this.handleCheckbox(event)
                    }}
                  />
                  <p>I have read and accept the Terms & Conditions</p>
                </div>
                <Button disabled={!this.state.accepted} type="submit" onClick={this.acceptLegal} variant="contained" style={muiTheme.button.primary} className="button">Continue</Button>
              </footer>
            </div>
          </div>
        </Portal>
      )
    }
    return true
  }
}

Legal.propTypes = {
  acceptLegal: PropTypes.func,
  currentUser: PropTypes.object,
  currentLegal: PropTypes.object
}

export default Legal
