import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'

import classNames from 'classnames'
import { dismissSnack } from 'common/actions'

const mapStateToProps = ({ snackbar, currentUser }) => {
  return {
    currentUser: currentUser,
    message: snackbar.message,
    action: snackbar.action || 'Got it',
    isOpen: snackbar.open
  }
}

const mapDispatchToProps = { dismissSnack }

class SnackBar extends React.Component {
  slideUp (props) {
    return <Slide {...props} direction="up" />
  }

  render () {
    let isTherapist
    if (this.props.currentUser.roles) {
      isTherapist = this.props.currentUser.roles.find((role) => {
        return role === 'therapist' || role === 'administrator'
      })
    }

    if (!this.props.message) {
      return null
    }
    return (
      <Snackbar
        className={classNames('snackbar', { 'therapist': isTherapist })}
        open={this.props.isOpen}
        autoHideDuration={4000}
        onClose={this.props.dismissSnack}
        TransitionComponent={this.slideUp}
        message={this.props.message && <span>{this.props.message}</span>}
        action={
          <Button style={{ color: this.props.theme.palette.secondary.main }}
            color="secondary" size="small"
            onClick={this.props.dismissSnack}>
            {this.props.action}
          </Button>
        }
      />
    )
  }
}

export default compose(withStyles(null, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(SnackBar)
