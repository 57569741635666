import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux'
import moment from 'moment'

import update from 'immutability-helper'
import _ from 'lodash'

/**
 * Takes either an UPDATE_LOCATION or DELETE_LOCATION action,
 * finds the location in the given group, updates it or deletes it,
 * and returns the new group object.
 *
 * @param {object} action The action from the reducer
 * @param {object} group The group object containing the location which to apply this action to.
 * @returns {object} The new group object.
 */
function applyLocationActionToGroup (action, group) {
  let { data: location } = action

  let locationIndex = group.locations.findIndex(item => item._id === location._id)
  if (locationIndex !== -1) {
    switch (action.type) {
      case 'UPDATE_LOCATION': {
        // update the location in the list of locations
        return update(group, { locations: { $splice: [[ locationIndex, 1, location ]] } })
      }
      case 'DELETE_LOCATION': {
        // delete the location from the list of locations
        return update(group, { locations: { $splice: [[ locationIndex, 1 ]] } })
      }
      default: {
        break
      }
    }
  }
  return group
}

const status = (state = { isLoading: true }, action) => {
  switch (action.type) {
    case 'USER_IS_LOGGED_IN':
    case 'USER_IS_ANONYMOUS': {
      return { isLoading: false }
    }
    default: {
      return state
    }
  }
}

const currentUser = (state = {}, action) => {
  switch (action.type) {
    case 'USER_IS_LOGGED_IN': {
      let user = action.user
      return { ...user }
    }
    case 'USER_IS_ANONYMOUS': {
      return {}
    }
    case 'UPDATE_LOCATION':
    case 'DELETE_LOCATION': {
      let { data: location } = action

      // find the group index for this location in the user's groups
      let groupIndex = state.groups.findIndex(item => item._id === location.group)
      if (groupIndex !== -1) {
        let group = state.groups[groupIndex]
        group = applyLocationActionToGroup(action, group)
        // update the group
        state = update(state, { groups: { $splice: [[ groupIndex, 1, group ]] } })
      }
      return state
    }
    default:
      return state
  }
}

const currentPatient = (state = { coreEvaluations: undefined, assessmentResults: undefined, }, action) => {
  switch (action.type) {
    case 'SET_PATIENT':
      return action.data
    case 'GET_LAST_ALLCORE_SESSION':
      return { ...state, lastRide: action.data }
    case 'GET_CORE_EVALUATIONS': {
      // Get all sessions, not core evaluations
      let rides = _.filter(state.rides, (session) => {
        return !session.durations
      })

      // Add in our results
      action.data.forEach((evaluation) => {
        evaluation.startTime = evaluation.date
        rides.push(evaluation)
      })

      // Sort
      let sortedSessions = _.orderBy(rides, ['startTime'], ['desc'])

      // Set up graph friendly data
      let coreEvaluationGraphData = []
      action.data.forEach((session) => {
        let existing = coreEvaluationGraphData.find((evaluation) => {
          return moment(evaluation.date).format('MM-DD-YYYY') === moment(session.startTime).format('MM-DD-YYYY')
        })
        if (!existing) {
          coreEvaluationGraphData.push(session)
        }
        else {
          existing.score += session.score
        }
      })

      return { ...state, coreEvaluations: action.data, coreEvaluationGraphData: coreEvaluationGraphData, rides: sortedSessions }
    }

    case 'GET_RIDES': {
      let rides = action.data

      let totalSessions = 0
      let totalMonth = 0
      let totalQuarter = 0
      let totalYear = 0
      let allCoreGraphData = []

      // Go through all the sessions to break them out by date
      rides.forEach((session) => {
        totalSessions += 1

        let startTime = session.startTime
        let degrees = []
        let sessionSpins = 0
        let coreScore = session.coreScore

        let weightedAngle = function (angle) {
          return angle.reduce(function (a, b) {
            return a + b
          }, 0)
        }

        // Go through each result to get our graph data structured for the component
        // Add up the spins for this session
        session.sets.forEach((set) => {
          if (set) {
            sessionSpins += set.spins
          }
        })

        // Push our angle multiplied by the number of spins at that angle divided by total spins
        session.sets.forEach((set) => {
          if (set) {
            degrees.push((set.spins / sessionSpins) * set.degrees)
          }
        })

        // Check to see if we've dealt with this day already
        let existingDay = allCoreGraphData.find((day) => {
          return moment(day.startTime).format('YYYY-MM-DD') === moment(startTime).format('YYYY-MM-DD')
        })

        // If we've already got a session for this day
        if (existingDay) {
          // Average out the angle data
          let existingAngle = existingDay.angle
          let averageAngle = (existingAngle + Math.round(weightedAngle(degrees))) / 2
          existingDay.angle = Math.round(averageAngle)

          // Update the spin count
          let existingSpins = existingDay.spins
          existingDay.spins = existingSpins + sessionSpins

          // Combine the scores
          let existingScore = existingDay.coreScore
          existingDay.coreScore = existingScore + coreScore
        }
        // Otherwise add it to our graph data
        else {
          allCoreGraphData.push({ startTime: startTime, angle: Math.round(weightedAngle(degrees)), spins: sessionSpins, coreScore: coreScore })
        }

        // Break everything out by date and add up our total rides
        let dateTo = moment()
        let dateFrom

        dateFrom = moment().startOf('year')
        if (moment(startTime).isBetween(dateFrom, dateTo)) {
          totalYear += 1
        }

        dateFrom = moment().startOf('quarter')
        if (moment(startTime).isBetween(dateFrom, dateTo)) {
          totalQuarter += 1
        }

        dateFrom = moment().startOf('month')
        if (moment(startTime).isBetween(dateFrom, dateTo)) {
          totalMonth += 1
        }
      })

      // Set our totals
      let rideData = {}

      rideData.totalYear = totalYear
      rideData.totalQuarter = totalQuarter
      rideData.totalMonth = totalMonth
      rideData.allTime = totalSessions

      return { ...state, rides: rides, lastRide: rides[0], rideData: rideData, allCoreGraphData: allCoreGraphData }
    }
    case 'RECORD_RIDE':
      return { ...state, lastRide: action.data }

    case 'CREATE_ASSESSMENT_RESULT':
      return state
    case 'UPDATE_ASSESSMENT_RESULTS':

      if (state._id === action.id) {
        let assessmentResults = action.data.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
        let results = assessmentResults.reverse()
        let lastAssessment = results.find((result) => {
          return result._bundle === 'assessment'
        })
        let lastNote = results.find((result) => {
          return result._bundle === 'note'
        })
        let lastQuestionnaire = results.find((result) => {
          return result._bundle === 'odi' || result._bundle === 'ndi'
        })

        return {
          ...state,
          assessmentResults: results,
          lastAssessment: lastAssessment,
          lastNote: lastNote,
          lastQuestionnaire: lastQuestionnaire
        }
      }
      else {
        return { ...state, assessmentResults: [] }
      }
    default:
      return state
  }
}

const groups = (state = [], action) => {
  switch (action.type) {
    case 'GET_ALL_GROUPS': {
      return action.data
    }
    case 'UPDATE_LOCATION':
    case 'DELETE_LOCATION': {
      let { data: location } = action

      // find the group index for this location in the list of groups
      let groupIndex = state.findIndex(item => item._id === location.group)
      if (groupIndex !== -1) {
        let group = state[groupIndex]
        group = applyLocationActionToGroup(action, group)
        // update the group
        state = update(state, { $splice: [[ groupIndex, 1, group ]] })
      }
      return state
    }
    default: {
      return state
    }
  }
}

const machines = (state = [], action) => {
  switch (action.type) {
    case 'GET_MACHINES': {
      state = action.data
      return state
    }
    default: {
      return state
    }
  }
}

const currentLegal = (state = {}, action) => {
  switch (action.type) {
    case 'SET_CURRENT_LEGAL': {
      state = action.data
      return state
    }
    default: {
      return state
    }
  }
}

const patients = (state = { searchQuery: '', data: [] }, action) => {
  switch (action.type) {
    case 'GET_PATIENTS':
      // This will replace our existing list of patients
      return {
        data: action.data,
        nextPage: action.nextPage,
        total: action.total
      }
    case 'GET_MORE_PATIENTS':
      return {
        // If we have existing patients, combine them and only return unique patients
        data: _.uniqBy(state.data.concat(action.data), '_id'),
        nextPage: action.nextPage,
        total: action.total
      }
    case 'SET_SEARCH_QUERY':
      return { ...state, searchQuery: action.searchQuery }
    default:
      return state
  }
}

const reports = (state = { data: {} }, action) => {
  switch (action.type) {
    case 'GET_REPORT': {
      // Store the report by key
      return update(state, { data: { [action.key]: { $set: action.data } } })
    }
    default: {
      return state
    }
  }
}

const snackbar = (state = {}, action) => {
  switch (action.type) {
    case 'NEW_MESSAGE':
      return { ...state, message: action.message, action: action.action, open: true }
    case 'DISMISS_MESSAGE':
      return { ...state, open: false }
    default:
      return state
  }
}

const targetTab = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TARGET_TAB':
      return action.tab
    case 'CLEAR_TARGET_TAB':
      return null
    default:
      return state
  }
}

const leaderboard = (state = { timePeriod: 'thisWeek', reportType: 'single', data: {} }, action) => {
  switch (action.type) {
    case 'CHANGE_LEADERBOARD_TIME_PERIOD': {
      return { ...state, timePeriod: action.data }
    }
    case 'CHANGE_LEADERBOARD_REPORT_TYPE': {
      return { ...state, reportType: action.data }
    }
    case 'UPDATE_LEADERBOARD': {
      return update(state, { data: { [action.key]: { $set: action.data } } })
    }
    default: {
      return state
    }
  }
}

const tabHistory = (state = [], action) => {
  switch (action.type) {
    case 'USER_IS_LOGGED_IN': {
      let { user } = action
      return [
        { page: 'leaderboard', currentTab: user.roles.includes('administrator') ? 'group-all' : 'location-all' },
        { page: 'rideData', currentTab: 0 },
        { page: 'riders', currentTab: 0 }
      ]
    }
    case 'CHANGE_TAB': {
      let history = state
      let pageHistory = history.find((page) => {
        return page.page === action.page
      })
      pageHistory.currentTab = action.tab

      return history
    }
    default: {
      return state
    }
  }
}

const expansionPanels = (state = { activePanel: {} }, action) => {
  switch (action.type) {
    case 'EXPAND_PANEL': {
      state = update(state, { activePanel: { [action.group]: { $set: action.key } } })
      return state
    }
    case 'COLLAPSE_PANEL': {
      state = update(state, { activePanel: { [action.group]: { $set: null } } })
      return state
    }
    default: {
      return state
    }
  }
}

const announcements = (state = [], action) => {
  switch (action.type) {
    case 'GET_ANNOUNCEMENTS': {
      return action.data.data
    }
    default: {
      return state
    }
  }
}

const reducers = combineReducers({
  status,
  currentUser,
  currentPatient,
  leaderboard,
  groups,
  machines,
  patients,
  reports,
  snackbar,
  targetTab,
  tabHistory,
  expansionPanels,
  announcements,
  currentLegal,
  form: formReducer,
  routing: routerReducer
})

export default reducers
