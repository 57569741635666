import React, { Component } from 'react'
import PropTypes from 'prop-types'

class NumericInput extends Component {
  constructor (props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      value: this.props.value
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value })
    }
  }

  handleClick () {
    this.inputRef.current.select()
  }

  handleChange (event) {
    if (!isNaN(event.target.value)) {
      this.setState({ value: event.target.value.length ? Number(event.target.value) : '' })
    }
  }

  render () {
    return React.createElement('input', {
      disabled: this.props.disabled,
      size: 2,
      type: 'number',
      pattern: '[0-9]*',
      style: { color: this.props.color },
      maxLength: 2,
      ref: this.inputRef,
      onClick: () => this.handleClick(),
      onChange: (event) => this.handleChange(event),
      onBlur: () => this.props.onChange(this.state.value),
      value: this.state.value,
    })
  }
}

NumericInput.propTypes = {
  value: PropTypes.number,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  onChange: PropTypes.func
}

export default NumericInput
