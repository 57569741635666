import app from 'common/client/app'
import streamSaver from 'streamsaver'
import moment from 'moment'

/**
 * Fetch a single user
 */
export function getUser (id) {
  return async dispatch => {
    let user = await app.service('user').get(id)
    return user
  }
}

/**
 * Set the patient search query in the store
 */
export function setPatientSearchQuery (query) {
  return async dispatch => {
    dispatch({ type: 'SET_SEARCH_QUERY', searchQuery: query })
  }
}

/**
 * Fetch riders for a specific group with or without a search query
 */
export function getPatients (params) {
  return async (dispatch, getState) => {
    let query = {
      'group': params.groupId,
      '$skip': params.page * 100,
      '$sort': {
        'name.family': '1'
      },
    }

    // Only ask the server to do all of this if we actually have a query
    let searchQuery = getState().patients.searchQuery
    if (searchQuery) {
      query['$or'] = [
        {
          'name.given': {
            $regex: searchQuery,
            $options: 'i'
          }
        },
        {
          'name.family': {
            $regex: searchQuery,
            $options: 'i'
          }
        },
        {
          'email': {
            $regex: searchQuery,
            $options: 'i'
          }
        },
        {
          'patientID': {
            $regex: searchQuery,
          }
        }
      ]
    }

    let patients = await app.service('patient').find({ query: query })

    dispatch({
      type: params.type,
      data: patients.data,
      nextPage: params.page + 1,
      total: patients.total
    })
  }
}

/**
 * Get patient by _id
 */
export function getPatient (id) {
  return async dispatch => {
    dispatch({ type: 'SET_PATIENT', data: null })

    let patient
    if (id) {
      patient = await app.service('patient').get(id)
      dispatch({ type: 'SET_PATIENT', data: patient })
    }
    else {
      dispatch({ type: 'SET_PATIENT', data: null })
    }
    return patient
  }
}

/**
 * Get all groups app-wide for admins
 */
export function getAllGroups () {
  return async dispatch => {
    let groups = await app.service('group').find()
    dispatch({ type: 'GET_ALL_GROUPS', data: groups })
    // @todo get the locations for these groups...

    return groups
  }
}

/**
 * Fetch machines and add them to the store, optionally based on some criteria
 */
export function getMachines (group) {
  return async dispatch => {
    let { data } = await app.service('machine').find({ query: { group } })
    return dispatch({ type: 'GET_MACHINES', data })
  }
}

/**
 * Get announcements
 */
export function getAnnouncements () {
  return async dispatch => {
    let announcements = await app.service('userAnnouncement').find()
    dispatch({ type: 'GET_ANNOUNCEMENTS', data: announcements })

    return announcements
  }
}

/**
 * Update user announcement log
 * @todo this should really be named `createUserAnnouncementLog` since it creates a new document
 */
export function updateUserAnnouncementLog (announcementId) {
  return async dispatch => {
    await app.service('userAnnouncementLog').create({ announcement: announcementId })
  }
}

/**
 * Find all completed program sessions for a patient by patient _id
 */
export function getPatientRides (id) {
  return async dispatch => {
    let rides = await app.service('ride').find({
      query: {
        'patient': id,
        '$sort': {
          'startTime': -1,
        },
      },
    })

    // Only return rides that have at least one completed spin and a coreScore
    const completeRides = rides.filter(ride => ride.sets.length).filter(ride => ride.coreScore)
    dispatch({ type: 'GET_RIDES', data: completeRides })

    return rides
  }
}

export function getPatientAssessments (id, options = {}) {
  return async dispatch => {
    let results = await app.service('assessment-result').find({ query: { patient: id } })
    dispatch({ type: 'UPDATE_ASSESSMENT_RESULTS', id, data: results.data })
  }
}

export function savePatientAssessment (assessment, assessmentType, patient) {
  let message = assessmentType + ' saved for ' + patient.name.given + ' ' + patient.name.family + '.'

  return async dispatch => {
    await app.service('assessment-result').create(assessment)
    dispatch({ type: 'NEW_MESSAGE', message: message, action: 'Got it' })

    return true
  }
}

export function updatePatientAssessment (assessment, assessmentType, patient) {
  let message = assessmentType + ' updated for ' + patient.name.given + ' ' + patient.name.family + '.'

  return async dispatch => {
    await app.service('assessment-result').update(assessment._id, assessment, {
      query: {
        _bundle: assessment._bundle,
      }
    })
    dispatch({ type: 'NEW_MESSAGE', message: message, action: 'Got it' })
    return true
  }
}

export function deletePatientAssessment (id, type, startTime) {
  return async dispatch => {
    let message = type + ' on ' + startTime + ' deleted.'
    await app.service('assessment-result').remove(id)

    dispatch({ type: 'NEW_MESSAGE', message: message, action: 'Got it' })

    return true
  }
}

/**
 * Return top ten ride scorers for a Report Type, Time Period, Group, Location
 */
export function getLeaderboard (options) {
  let { reportType, timePeriod, groupId, locationId } = options

  return async dispatch => {
    let dateQuery
    let firstDay

    if (timePeriod === 'thisWeek') {
      let sunday = new Date().getDate() - new Date().getDay()
      firstDay = new Date(new Date().setDate(sunday)).toUTCString()
      dateQuery = { $lt: new Date(), $gte: firstDay }
    }
    if (timePeriod === 'thisMonth') {
      firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      dateQuery = { $lt: new Date(), $gte: firstDay }
    }
    if (timePeriod === 'thisYear') {
      firstDay = new Date(new Date().getFullYear(), 0, 1)
      dateQuery = { $lt: new Date(), $gte: firstDay }
    }
    if (timePeriod === 'allTime') {
      dateQuery = { $lt: new Date() }
    }

    let query = {
      '$limit': 10,
    }

    if (locationId && locationId !== 'all') {
      query.location = locationId
    }
    if (groupId && groupId !== 'all') {
      query.group = groupId
    }

    query.date = dateQuery

    let results = await app.service('report').get(reportType === 'single' ? 'coreScore' : 'coreScoreTotal', { query })
    let patientIds = results.map(result => result.patient)
    if (patientIds) {
      let { data: patients } = await app.service('patient').find({ query: { _id: { $in: patientIds } } })
      results.forEach(result => {
        result.patient = patients.find(patient => patient._id === result.patient)
      })
    }

    let key = locationId ? `${reportType}-${timePeriod}-${groupId}-${locationId}` : `${reportType}-${timePeriod}-${groupId}`
    dispatch({ type: 'UPDATE_LEADERBOARD', key, data: results })

    return results
  }
}

/**
 *  Submit a manually recorded AllCore360 session
 */
export function recordRide (patient, sets, location, machine) {
  return async dispatch => {
    let newSession = await app.service('ride').create(
      {
        patient: patient._id,
        group: patient.group,
        location: location,
        machine,
        sets: sets,
        status: true
      },
      // tell the service to send a ride complete email, if the user wants it.
      { query: { $client: { sendMail: true } } }
    )

    let patientName = patient.name.given + ' ' + patient.name.family
    let message = 'AllCore360 ride saved for ' + patientName + '.'

    dispatch({ type: 'RECORD_RIDE', data: newSession })
    dispatch({ type: 'NEW_MESSAGE', message: message, action: 'Got it' })

    return newSession
  }
}

/**
 *  Submit a manually recorded Core Evaluation
 */
export function recordCoreEvaluation (evaluation) {
  return async dispatch => {
    let newEval = await app.service('core-evaluation').create(evaluation, { query: { $client: { sendMail: true } } })
    return newEval
  }
}

/**
 *  Get Core Evaluations for a patient
 */
export function getCoreEvaluations (patientId) {
  return async dispatch => {
    let evals = await app.service('core-evaluation').find({
      query: {
        'patient': patientId,
      },
    })

    evals.data.forEach((evaluation) => {
      evaluation.startTime = evaluation.date
    })

    dispatch({ type: 'GET_CORE_EVALUATIONS', data: evals.data })

    return evals
  }
}

/**
 *  Delete a Core Evaluation
 */
export function deleteCoreEvaluation (id, startTime) {
  return async dispatch => {
    let deletedEvaluation = await app.service('core-evaluation').remove(id)
    let message = 'Core Evaluation on ' + startTime + ' deleted.'
    dispatch({ type: 'NEW_MESSAGE', message: message, action: 'Got it' })

    return deletedEvaluation
  }
}

/**
 *  Update a core evaluation
 */
export function updateCoreEvaluation (evaluation, startTime) {
  return async dispatch => {
    let updatedEvaluation = await app.service('core-evaluation').update(evaluation._id, evaluation)
    let message = 'Core Evaluation on ' + startTime + ' updated.'
    dispatch({ type: 'NEW_MESSAGE', message: message, action: 'Got it' })

    return updatedEvaluation
  }
}

/**
 *  Update a session
 */
export function updateRide (session, startTime) {
  return async dispatch => {
    let updatedSession = await app.service('ride').update(session._id, session)
    let message = 'AllCore360 ride on ' + startTime + ' updated.'
    dispatch({ type: 'NEW_MESSAGE', message: message, action: 'Got it' })

    return updatedSession
  }
}

/**
 *  Delete a session
 */
export function updateSession (id, startTime) {
  return async dispatch => {
    let deletedSession = await app.service('ride').remove(id)
    let message = 'AllCore360˚ ride on ' + startTime + ' deleted.'

    dispatch({ type: 'NEW_MESSAGE', message: message, action: 'Got it' })

    return deletedSession
  }
}

/**
 *  Get locations for a specific group
 */
export function getLocations (id) {
  return async dispatch => {
    let locations = await app.service('location').find({
      query: {
        'group': id,
      },
    })

    dispatch({ type: 'GET_GROUP_LOCATIONS', group: id, locations: locations.data })

    return locations
  }
}

/**
 *  Add a new patient
 */
export function addNewPatient (data) {
  return async dispatch => {
    try {
      let newPatient = await app.service('patient').create(data)
      dispatch({ type: 'NEW_MESSAGE', message: data.name.given + ' ' + data.name.family + ' added.', action: 'Got it' })

      return newPatient
    }
    // Handle any errors with patientID or email
    catch (err) {
      // If err.errors exists, use that, otherwise use err.message
      let error = err.errors ? Object.values(err.errors).join(' ') : err.message
      dispatch({ type: 'NEW_MESSAGE', message: 'Uh oh! ' + error, action: 'Got it' })
    }
  }
}

/**
 *  Update an existing patient
 */
export function updatePatient (patient) {
  return async dispatch => {
    let updatedPatient = await app.service('patient').patch(patient._id, patient)
    let message = updatedPatient.name.given + ' ' + updatedPatient.name.family + ' updated.'

    dispatch({ type: 'NEW_MESSAGE', message: message, action: 'Got it' })

    return updatedPatient
  }
}

/**
 *  Update an existing location
 */
export function updateLocation (location) {
  return async dispatch => {
    let updatedLocation = await app.service('location').patch(location._id, location)
    dispatch({ type: 'UPDATE_LOCATION', data: location })
    dispatch({ type: 'NEW_MESSAGE', message: location.title + ' updated.', action: 'Got it' })

    return updatedLocation
  }
}

/**
 *  Delete a location
 */
export function deleteLocation (location) {
  return async dispatch => {
    let removedLocation = await app.service('location').remove(location._id)
    dispatch({ type: 'DELETE_LOCATION', data: location })
    dispatch({ type: 'NEW_MESSAGE', message: location.title + ' deleted.', action: 'Got it' })

    return removedLocation
  }
}

/**
 * Get a report for given options, and save it by key. The report object is an
 * array of months with number of rides and total cost for each month
 *
 * @param {string} key A unique key to find this report in the store
 * @param {{ [group: string], [location: string], dateFrom: object, dateTo: object }} options
 * dateTo and dateFrom can be moment instances, date objects, or strings
 */
export function getReport (key, options) {
  return async dispatch => {
    let query = {}
    let { group, location, dateFrom, dateTo } = options

    if (!dateFrom || !dateTo) {
      throw new Error('`dateFrom` and `dateTo` are required.')
    }

    if (group) {
      query = { ...query, group }
    }
    if (location) {
      query = { ...query, location }
    }

    dateFrom = moment(dateFrom)
    dateTo = moment(dateTo).add(1, 'day')
    query = {
      ...query,
      date: {
        $gte: dateFrom.toDate(),
        $lt: dateTo.toDate(),
      }
    }

    let results = await app.service('report').get('rides', { query })

    // Calculate the number of months from the start date to the end date or the current date
    let numMonths = moment.min(moment(), dateTo.endOf('month')).diff(dateFrom.startOf('month'), 'months', true)
    let report = []

    // Count through the number of months and fill in any missing months
    for (let i = 0; i < numMonths; i++) {
      // Get this month, without mutating the original from date
      let month = dateFrom.clone().add(i, 'month').format('YYYY-MM')
      // Find the report for this month, and push it to the array
      let monthReport = results.find(item => item._id === month)
      report.push(monthReport || {
        numRides: 0,
        totalCost: 0,
        _id: month,
      })
    }
    report.reverse()

    // Update reports by key...
    dispatch({ type: 'GET_REPORT', key, data: report })
    return report
  }
}

export function downloadRideData (timestamp) {
  return async dispatch => {
    let token = await app.passport.getJWT()
    let options = {
      headers: {
        'Authorization': token,
        'Accept': 'text/csv; charset=utf-8'
      },
    }

    let response = await fetch((process.env.REACT_APP_API_URL || '/') + 'report/ridesAll', options)
    if (response.ok) {
      // Get the filename
      let filename
      let disposition = response.headers.get('content-disposition')
      if (disposition) {
        let filenamePart = disposition.split(';').find(part => part.match(/filename=/))
        if (filenamePart) {
          filename = filenamePart.split('=')[1]
        }
      }
      if (!filename) {
        // show a message
        dispatch({ type: 'NEW_MESSAGE', message: 'Unable to save ride data', action: 'Got it' })
        return false
      }

      let fileStream = streamSaver.createWriteStream(filename)
      let writer = fileStream.getWriter()
      let reader = response.body.getReader()

      // If we're done writing, close the stream, otherwise write a chunk then get the next one
      // @todo should this await?
      let pump = () => reader.read().then(({ value, done }) => done ? writer.close() : writer.write(value).then(pump))

      // Start the reader
      pump()

      return true
    }
    return false
  }
}

export function changeLeaderboardTimePeriod (timePeriod) {
  return dispatch => {
    dispatch({ type: 'CHANGE_LEADERBOARD_TIME_PERIOD', data: timePeriod })
  }
}

export function changeLeaderboardReportType (reportType) {
  return dispatch => {
    dispatch({ type: 'CHANGE_LEADERBOARD_REPORT_TYPE', data: reportType })
  }
}

export function updateTabHistory (page, tab) {
  return dispatch => {
    dispatch({ type: 'CHANGE_TAB', page: page, tab: tab })
  }
}

export function expandPanel (group, key) {
  return dispatch => {
    dispatch({ type: 'EXPAND_PANEL', group, key })
  }
}

export function collapsePanel (group, key) {
  return dispatch => {
    dispatch({ type: 'COLLAPSE_PANEL', group, key })
  }
}

export function setTargetTab (tab) {
  return dispatch => {
    dispatch({ type: 'SET_TARGET_TAB', tab: tab })
  }
}

export function clearTargetTab () {
  return dispatch => {
    dispatch({ type: 'CLEAR_TARGET_TAB' })
  }
}

/**
 *  Update waiver settings
 */
export function updateWaiverSettings (group) {
  return async dispatch => {
    let updatedGroup = await app.service('group').patch(group._id, group)
    return updatedGroup
  }
}
