import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { ResponsiveLine } from '@nivo/line'
import moment from 'moment'

const styles = theme => (null)

class ToolTip extends React.Component {
  render () {
    let personalBest

    if (this.props.coreScore) {
      personalBest = Math.max.apply(null, this.props.data.map(score => score.y))
    }

    return (
      <div className="tooltip">
        <header>{this.props.date}</header>
        <ul>
          {
            !isNaN(this.props.coreScore) &&
          <li>
            <span className="legendKey" style={{ backgroundColor: this.props.colors[0] }}></span>
            <span>Core Score </span>
            <span style={{ color: this.props.colors[0] }} className='value'>{this.props.coreScore}</span>
          </li>
          }
          {
            !isNaN(this.props.score) &&
          <li>
            <span className="legendKey" style={{ backgroundColor: this.props.colors[0] }}></span>
            <span>Evaluation Score </span>
            <span style={{ color: this.props.colors[0] }} className='value'>{this.props.score.toFixed(1)}</span>
          </li>
          }
          {
            this.props.angle &&
          <li>
            <span className="legendKey" style={{ backgroundColor: this.props.colors[0] }}></span>
            <span>Average Angle </span>
            <span style={{ color: this.props.colors[0] }} className='value'>{this.props.angle}</span>
            <i style={{ color: this.props.colors[0] }}>°</i>
          </li>
          }
          {
            this.props.spins &&
          <li>
            <span className="legendKey" style={{ backgroundColor: this.props.colors[1] }}></span>
            <span>Total Spins </span>
            <span style={{ color: this.props.colors[1] }} className='value'>{this.props.spins}</span>
          </li>
          }
        </ul>
        {
          personalBest && personalBest === this.props.coreScore &&
          <span style={{ marginTop: -10, display: 'block', fontSize: 12, color: this.props.colors[0] }} className='value'>Personal Best</span>
        }

      </div>
    )
  }
}

class LineGraph extends React.Component {
  createToolTip = (item, type, data) => {
    let { theme } = this.props

    if (type === 'allcore360') {
      let date = moment(item.data[0].data.x).format('MMMM Do, YYYY')
      let colors = [theme.palette.secondary.main, theme.palette.primary.light]

      if (this.props.graphSet === 'rideData') {
        return <ToolTip date={date} colors={colors} spins={item.data[0].data.y} angle={item.data[1].data.y}/>
      }
      if (this.props.graphSet === 'coreScore') {
        return <ToolTip date={date} colors={colors} data={data[0].data} coreScore={item.data[0].data.y}/>
      }
      if (this.props.graphSet === 'coreEvaluation') {
        return <ToolTip data={data[0].data} date={date} colors={colors} score={item.data[0].data.y}/>
      }
    }
  }

  render () {
    const { theme } = this.props

    if (this.props.data.length > 0) {
      let dotSize = 8
      let minY = this.props.minY
      let maxY = this.props.maxY

      if (this.props.data[0].data.length > 30) {
        dotSize = 0
      }

      if (this.props.data[0].id === 'Core Score') {
        minY = 'auto'
        maxY = 'auto'
      }

      return (
        <div className="graphWrapper" style={{ 'background': 'linear-gradient(to bottom, rgba(0,0,0,.35) 0%, rgba(0,0,0,.55) 100%)' }}>
          <ResponsiveLine
            data={this.props.data}
            margin={{
              'top': 10,
              'right': 20,
              'bottom': 10,
              'left': 40
            }}
            xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day' }}
            yScale={{
              'type': 'linear',
              'min': minY,
              'max': maxY
            }}
            axisBottom={{
              format: '%b %d',
              'tickSize': 3
            }}
            axisLeft={{
              'orient': 'left',
              'tickSize': 0,
              'tickPadding': 15,
              'tickRotation': 0,
            }}
            lineWidth={4}
            colors={[theme.palette.secondary.main, theme.palette.primary.light]}
            dotSize={dotSize}
            dotColor={'white'}
            tooltip={(item) => this.createToolTip(item, this.props.type, this.props.data)}
            dotBorderWidth={3}
            dotBorderColor={'inherit'}
            enableDotLabel={false}
            animate={true}
            enableGridX={false}
          />

        </div>
      )
    }
    else {
      return false
    }
  }
}

export default withStyles(styles, { withTheme: true })(LineGraph)
