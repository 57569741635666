import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'

// Material UI Components
import Portal from '@material-ui/core/Portal'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

// Components
import Navbar from './Navbar'
import TabsDateRange from './TabsDateRange'
import ReportsRideData from './ReportsRideData'
import Loading from './Loading'

// Actions
import { updateTabHistory, downloadRideData } from '../actions'

// Styles
import '../styles/index.scss'
import '../styles/reports.scss'

// Utilities
import moment from 'moment'
import classNames from 'classnames'

const styles = theme => (null)

const mapStateToProps = ({ currentUser, tabHistory }, ownProps) => {
  let { roles, groups } = currentUser

  let props = {
    currentUser,
    tabHistory,
    roles,
    groups,
  }

  // Create the structure of child views to be rendered
  // in the SwipeableViews component. Borrowed from LeaderboardLayout.
  // @todo turn this into a higher-order component.
  let childrenProps = [
    ...groups.map(group => ({
      key: `group-${group._id}`,
      group: group._id,
      title: group.title,
      locations: group.locations.map(location => ({
        key: `location-${location._id}`,
        _id: location._id,
        group: group._id,
        title: location.title,
        costPerSpin: location.costPerSpin,
        numFreeSpins: location.numFreeSpins
      }))
    }))
  ]

  // Add in an "All Groups" entry if we've got more than 1
  if (childrenProps.length > 1) {
    childrenProps.unshift({
      key: 'group-all',
      group: 'all',
      title: 'All Groups',
    })
  }

  props = { ...props, childrenProps }
  return props
}

const mapDispatchToProps = {
  updateTabHistory,
  downloadRideData,
}

class ReportsRideDataLayout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      ...this.state,
      currentDateRange: 'thisMonth',
      dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      currentTab: this.props.childrenProps[0].key,
      currentLocation: 'all',
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // If the tab changes, change the current location back to 'all'
    // @todo This will trigger an extra render, but so be it.
    if (this.state.currentTab !== prevState.currentTab) {
      this.setState({ currentLocation: 'all' })
    }
  }

  async download () {
    // @todo downloadRideData doesn't seem to pass the query parameters
    // for the current report. Is this intended?
    this.setState({ downloading: true })
    await this.props.downloadRideData(moment().format('MM-DD-YYYY'))
    this.setState({ downloading: false })
  }

  changeTab = (event, value) => {
    window.scrollTo(0, 0)

    let currentTab = value
    if (value.key) {
      currentTab = value.key
    }
    else if (value.props) {
      currentTab = value.props.value
    }

    this.setState({ currentTab: currentTab, })
    this.props.updateTabHistory('rideData', currentTab)
  }

  changeLocation = (event, value) => {
    this.setState({ currentLocation: value.key })
  }

  changeDateRange = (value) => {
    this.setState({ currentDateRange: value })
  }

  changeDateInput = (name, value) => {
    this.setState({ [name]: value })
  }

  blurDateInput = (name, value) => {
    let date = moment(value)
    switch (name) {
      case 'dateFrom': {
        // If the `from` date is before 2015, set it to 2015.
        if (date.isBefore(moment('2015-01-01'))) {
          date = moment('2015-01-01')
        }
        break
      }
      case 'dateTo': {
        // If the `to` date is before 2015, set it to now.
        if (date.isBefore(moment('2015-01-01'))) {
          date = moment()
        }
        break
      }
      default: {
        break
      }
    }
    value = date.format('YYYY-MM-DD')
    // @todo don't change the value if the value hasn't changed.
    this.setState({ [name]: value })
  }

  onSwipe = (index, indexLatest, meta) => {
    window.scrollTo(0, 0)
    let childProps = this.props.childrenProps[index]

    this.setState({ currentTab: childProps.key })
    this.props.updateTabHistory('rideData', childProps.key)
  }

  render () {
    const { theme } = this.props

    // really? it's 2019
    let userAgent = navigator.userAgent.indexOf('Firefox') >= 0 ? 'firefox' : ''

    let selectProps = {
      className: 'groupSelect',
      onChange: this.changeTab,
      value: this.state.currentTab,
    }
    let tabsProps = {
      variant: 'fullWidth',
      classes: {
        root: classNames('navTabs', userAgent),
        indicator: 'tabsIndicator',
      },
    }

    let nav
    // Create the navigation elements. This is borrowed from LeaderboardLayout.
    // @todo create a higher order component

    // If the user only belongs to one group, we don't need "All Groups"
    if (this.props.groups.length === 1) {
      let group = this.props.groups[0]
      nav = <Tabs { ...tabsProps } value={`group-${group._id}`} onChange={this.changeTab}>
        <Tab disableRipple key={`group-${group._id}`} label={group.title} value={`group-${group._id}`} />
      </Tabs>
    }
    // If the user belongs to more than 5 groups, use the dropdown instead of tabs
    else if (this.props.groups.length >= 5) {
      nav = <div className="groupSelectWrapper">
        <Select { ...selectProps }>
          <MenuItem value='group-all'>All Groups</MenuItem>
          {
            this.props.groups.map(group => {
              return (
                <MenuItem key={`group-${group._id}`} value={`group-${group._id}`}>{group.title}</MenuItem>
              )
            })
          }
        </Select>
      </div>
    }
    // Otherwise render the tabs normally
    else {
      nav = <Tabs { ...tabsProps } value={this.state.currentTab} onChange={this.changeTab}>
        <Tab disableRipple value='group-all' label="All Groups" />
        {
          this.props.groups.map(group => {
            return (
              <Tab disableRipple key={`group-${group._id}`} label={group.title} value={`group-${group._id}`} />
            )
          })
        }
      </Tabs>
    }

    let currentGroup = this.props.childrenProps.find(props => props.key === this.state.currentTab)

    return (
      <div>
        <AppBar position="fixed" className="appBar">
          <Navbar title="Ride Data" />
          { nav }
        </AppBar>
        <div className="page">
          <h2>{currentGroup.title}</h2>
          {
            currentGroup && currentGroup.locations &&
            <div className="locationSelectWrapper">
              <Select
                className="locationSelect"
                value={this.state.currentLocation}
                onChange={this.changeLocation}>
                <MenuItem key="all" value="all">All Locations</MenuItem>
                {
                  currentGroup.locations.map(location => (<MenuItem key={location._id} value={location._id}>{location.title}</MenuItem>))
                }
              </Select>
            </div>
          }
          {
            <TabsDateRange monthFirst={true} activeTab={this.state.currentDateRange} action={this.changeDateRange}/>
          }
          {
            // @todo this should be a part of TabsDateRange, no?
            this.state.currentDateRange === 'dateRange' &&
              <div className="flexRow dateRange">
                <TextField
                  type="date"
                  id="dateFrom"
                  classes={{ root: 'form-group' }}
                  onChange={(event) => this.changeDateInput('dateFrom', event.target.value)}
                  onBlur={(event) => this.blurDateInput('dateFrom', event.target.value)}
                  value={this.state.dateFrom}
                />
                <i className="icon calendar"></i>
                <TextField
                  type="date"
                  id="dateTo"
                  classes={{ root: 'form-group' }}
                  onChange={(event) => this.changeDateInput('dateTo', event.target.value)}
                  onBlur={(event) => this.blurDateInput('dateTo', event.target.value)}
                  value={this.state.dateTo}
                />
              </div>
          }
          <SwipeableViews
            index={this.props.childrenProps.findIndex(childProps => childProps.key === this.state.currentTab)}
            onChangeIndex={this.onSwipe}
            animateHeight={false}>
            {
              this.props.childrenProps.map(childProps => {
                let props = {
                  ...childProps,
                  dateRange: this.state.currentDateRange,
                }
                if (this.state.currentDateRange === 'dateRange') {
                  props = {
                    ...props,
                    dateFrom: this.state.dateFrom,
                    dateTo: this.state.dateTo,
                  }
                }
                if (!props.location) {
                  props = {
                    ...props,
                    location: this.state.currentLocation,
                  }
                }
                return (
                  <ReportsRideData {...props} />
                )
              }
              )
            }
          </SwipeableViews>
          {
            this.props.roles.includes('administrator') &&
            <Portal>
              <footer className="flexRow download">
                {
                  this.state.downloading
                    ? <div>
                      <p>Preparing Download...</p>
                      <Loading/>
                    </div>
                    : <Button onClick={() => this.download()} variant="contained" style={theme.button.primary} className='button'>Download Ride Data</Button>
                }
              </footer>
            </Portal>
          }
        </div>
      </div>
    )
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(ReportsRideDataLayout)
