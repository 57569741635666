import React from 'react'

import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import ListExpandable from './ListExpandable'

import moment from 'moment'
import TabsDateRange from './TabsDateRange'

const mapStateToProps = (state, ownProps) => {
  let assessmentResults
  if (state.currentPatient.assessmentResults) {
    assessmentResults = state.currentPatient.assessmentResults.filter((assessment) => {
      return assessment._bundle === ownProps.type
    })
  }

  return {
    assessmentResults: assessmentResults,
    currentUser: state.currentUser,
    currentPatient: state.currentPatient
  }
}

class PatientNotesHistory extends React.Component {
  state = {
    assessmentResults: this.props.assessmentResults,
    activeTab: 'thisMonth',
    historyStart: moment().startOf('year').format('YYYY-MM-DD'),
    historyEnd: moment().format('YYYY-MM-DD'),
  }

  componentDidUpdate (prevProps) {
    if (prevProps.assessmentResults !== this.props.assessmentResults) {
      if (this.props.assessmentResults) {
        this.setState({ assessmentResults: this.props.assessmentResults.filter((result) => {
          return moment(result.createdAt).isBetween(moment().startOf('month'), moment().endOf('month'))
        })
        })
      }
    }
  }

  handleTabChange (activeTab, start = null, end = null) {
    let assessmentResults = this.props.assessmentResults
    let filtered

    if (assessmentResults) {
      switch (activeTab) {
        // This month
        default:
          filtered = assessmentResults.filter((result) => {
            return moment(result.createdAt).isBetween(moment().startOf('month'), moment().endOf('month'))
          })
          break
        case 'thisQuarter':
          filtered = assessmentResults.filter((result) => {
            return moment(result.createdAt).isBetween(moment().startOf('quarter'), moment().endOf('quarter'))
          })
          break

        case 'thisYear':
          filtered = assessmentResults.filter((result) => {
            return moment(result.createdAt).isBetween(moment().startOf('year'), moment().endOf('year'))
          })
          break

        case 'dateRange':
          filtered = assessmentResults.filter((result) => {
            let dateFrom = moment(start).format('YYYY-MM-DD')
            let dateTo = moment(end).add(1, 'day').format('YYYY-MM-DD')
            return moment(result.createdAt).isBetween(dateFrom, dateTo)
          })
          break
      }
    }

    this.setState({ activeTab: activeTab, assessmentResults: filtered })
  }

  handleHistoryDateChange (event, date) {
    switch (date) {
      case 'start':
        this.setState({ historyStart: event.currentTarget.value })
        this.handleTabChange('dateRange', event.currentTarget.value, this.state.historyEnd)
        break
      case 'end':
        this.setState({ historyEnd: event.currentTarget.value })
        this.handleTabChange('dateRange', this.state.historyStart, event.currentTarget.value)
        break
      default:
        return true
    }
  }

  render () {
    let type = this.props.type
    let questionnaireContent, listType
    if (type === 'odi' || type === 'ndi') {
      listType = 'questionnaire'
      if (type === 'odi') {
        questionnaireContent = this.props.content.ODI
      }
      else {
        questionnaireContent = this.props.content.NDI
      }
    }
    else {
      listType = this.props.type
    }

    return (
      <div>
        <TabsDateRange activeTab={this.state.activeTab} action={(activeTab) => this.handleTabChange(activeTab, this.state.historyStart, this.state.historyEnd)}/>
        {
          this.state.activeTab === 'dateRange' &&
          <div className="flexRow dateRange">
            <TextField
              type="date"
              id="dateFrom"
              classes={{ root: 'form-group' }}
              onChange={(event) => this.handleHistoryDateChange(event, 'start')}
              value={this.state.historyStart}
            />
            <i className="icon calendar"></i>
            <TextField
              type="date"
              id="dateTo"
              classes={{ root: 'form-group' }}
              onChange={(event) => this.handleHistoryDateChange(event, 'end')}
              value={this.state.historyEnd}
            />
          </div>
        }

        <ListExpandable type={listType} data={this.state.assessmentResults} patient={this.props.currentPatient} content={questionnaireContent} />

      </div>
    )
  }
}

export default connect(mapStateToProps)(PatientNotesHistory)
