import React from 'react'
import classNames from 'classnames'
import ParameterInput from 'common/components/ParameterInput'

import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import _ from 'lodash'
import Button from '@material-ui/core/Button'

import { connect } from 'react-redux'
import compose from 'recompose/compose'

import '../styles/rideConfig.scss'

import { showSnack } from 'common/actions'
import { getLocations, getMachines, getCoreEvaluations, getPatientRides, recordRide, clearTargetTab } from '../actions'

import { calculateCoreScore } from 'common/functions'

import RideConfig from './RideConfig'
import CoreEvaluationLayout from './CoreEvaluationLayout'
import RideTemplateSelect from './RideTemplateSelect'
import moment from 'moment'

const styles = theme => (null)

const mapStateToProps = ({ currentUser, currentPatient, machines, targetTab }) => {
  let patientGroup = currentUser.groups.find((group) => {
    return group._id === currentPatient.group
  })

  return {
    currentUser,
    currentPatient,
    locations: patientGroup ? patientGroup.locations : [],
    machines,
    targetTab,
  }
}

const mapDispatchToProps = {
  showSnack,
  getLocations,
  getMachines,
  getCoreEvaluations,
  getPatientRides,
  recordRide,
  clearTargetTab
}

class StartRide extends React.Component {
  defaultRide = { sets: [{ spins: 5, degrees: 45, direction: 'CW' }] }

  state = {
    /*
    **  rideType can be one of the following:
    **  'lastRide' -- The current user's last recorded AllCore360 ride
    **  'evaluation' -- Core Evaluation
    **  'template' -- A ride template with predefined sets, which can be modified by the user's suggested angle
    **  'custom' -- Anything that isn't one of the previous three options, defined by the user
    */
    rideType: 'evaluation',
    currentLocation: '',
    currentMachine: '',
    averageAngle: 45,
    ride: { ...this.defaultRide },
    personalBest: 0
  }

  async componentDidMount () {
    await this.props.getLocations(this.props.currentPatient.group)
    await this.props.getMachines(this.props.currentPatient.group)
    await this.props.getPatientRides(this.props.currentPatient._id)
    await this.props.getCoreEvaluations(this.props.currentPatient._id)

    let [ currentLocation ] = this.props.locations
    this.setState({
      currentLocation: currentLocation._id,
    })

    // If they have at least one ride, start with Last Ride and use it to set our average angle
    if (this.props.currentPatient.lastRide) {
      let ride = { ...this.props.currentPatient.lastRide }
      this.setState({
        rideType: 'lastRide',
        ride: ride,
        averageAngle: ride.averageAngle,
        projectedCoreScore: calculateCoreScore({ sets: ride.sets })
      })
    }
    // If they also have never done a core evaluation, get one ready
    else if (!this.props.currentPatient.coreEvaluations.length) {
      this.setState({
        rideType: 'evaluation',
        projectedCoreScore: calculateCoreScore({ sets: this.defaultRide.sets })
      })
    }
    // Otherwise set the rideType to custom and use the default ride
    else {
      this.setState({
        rideType: 'custom',
        ride: { ...this.defaultRide },
        projectedCoreScore: calculateCoreScore({ sets: this.defaultRide.sets })
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.isVisible !== this.props.isVisible) {
      if (this.props.targetTab && this.props.targetTab.length) {
        this.setState({ rideType: this.props.targetTab })
        this.props.clearTargetTab()
      }
    }

    if (prevProps.repeatedRide !== this.props.repeatedRide) {
      this.setState({ rideType: 'custom', ride: this.props.repeatedRide })
    }

    if (prevProps.currentPatient.lastRide !== this.props.currentPatient.lastRide) {
      if (this.props.currentPatient.rides) {
        let rides = this.props.currentPatient.rides

        // Get all the core scores to see the personal best
        let coreScores = rides.map(ride => ride.coreScore).filter(Number)
        this.setState({ personalBest: Math.max.apply(null, coreScores) })
      }
    }

    if (prevState.currentLocation !== this.state.currentLocation) {
      this.setState({ currentMachine: '' })
    }
  }

  setAverageAngle (value) {
    let newValue = _.clamp(value, 0, 90)
    this.setState({ averageAngle: newValue })
  }

  adjustAverageAngle (value) {
    let averageAngle = this.state.averageAngle
    let newValue = _.clamp(averageAngle + value, 0, 90)
    this.setState({ averageAngle: newValue })
  }

  changeRideType = (event) => {
    if (event.target.value === 'lastRide') {
      // Set the rideConfig back to the lastRide
      let newRide = { ...this.props.currentPatient.lastRide }
      this.setState({
        rideType: event.target.value,
        ride: newRide,
        projectedCoreScore: calculateCoreScore({ sets: newRide.sets })
      })
    }
    else {
      this.setState({ rideType: event.target.value })
    }
  }

  templateSwipe = index => {
    this.setState({ templateIndex: index })
  }

  changeLocation = (event) => {
    this.setState({ currentLocation: event.target.value })
  }

  changeMachine = (event) => {
    this.setState({ currentMachine: event.target.value })
  }

  selectTemplate = (ride) => {
    if (ride.sets) {
      ride.sets = ride.sets.map((set) => {
        return {
          degrees: set.degrees,
          direction: set.direction,
          spins: set.spins
        }
      })
      this.setState({
        rideType: 'custom',
        ride: ride,
        projectedCoreScore: calculateCoreScore({ sets: ride.sets })
      })
    }
  }

  handleRideConfig = (ride) => {
    this.setState({
      ride: ride,
      rideType: 'custom',
      projectedCoreScore: calculateCoreScore({ sets: ride.sets })
    })
  }

  afterEvaluation = (suggestedAngle) => {
    if (suggestedAngle) {
      // Set the state to custom and load a 5x5 ride with their suggested angle
      let ride = {
        sets: [
          { spins: 5, degrees: suggestedAngle, direction: 'CW' },
          { spins: 5, degrees: suggestedAngle, direction: 'CCW' },
        ]
      }
      this.setState({
        rideType: 'custom',
        ride: ride,
        averageAngle: suggestedAngle
      })
      let message = 'Core Evaluation saved for ' + this.props.currentPatient.name.given + ' ' + this.props.currentPatient.name.family + '.'
      this.props.showSnack(message)
    }
  }

  submitRide = async () => {
    const { currentPatient } = this.props
    const { ride, currentLocation, currentMachine } = this.state

    this.props.goHome()

    await this.props.recordRide(currentPatient, ride.sets, currentLocation, currentMachine || null)
    await this.props.getPatientRides(currentPatient._id)

    let message = 'AllCore360˚ ride saved for ' + currentPatient.name.given + ' ' + currentPatient.name.family + '.'
    this.props.showSnack(message)
  }

  render () {
    const { isVisible, locations, currentPatient, theme } = this.props
    const { rideType, currentLocation, currentMachine, personalBest, projectedCoreScore, suggestedAngle, averageAngle } = this.state

    let baseAngle = averageAngle
    if (suggestedAngle) {
      baseAngle = suggestedAngle
    }

    let waiverLink = 'https://www.smartwaiver.com/w/' + process.env.REACT_APP_SMARTWAIVER_ID + '/web/?wautofill_firstname=' + currentPatient.name.given + '&wautofill_lastname=' + currentPatient.name.family + '&wautofill_dobyyyymmdd=' + moment(currentPatient.dob).format('YYYYMMDD') + '&wautofill_tag=' + currentPatient._id + '&auto_anyoneelseneedtosign=0'
    let hideWaiver = currentPatient.waiverSignedAt || this.props.currentUser.groups[0].acceptWaiverTermsAt

    // If the patient is a minor, substitute this link
    if (moment(currentPatient.dob).isAfter(moment().subtract(18, 'years'))) {
      waiverLink = 'https://waiver.smartwaiver.com/auto/?auto_waiverid=' + process.env.REACT_APP_SMARTWAIVER_ID + '&auto_tag=' + currentPatient._id + '&auto_anyoneelseneedtosign=0'
    }

    const machines = this.props.machines.filter(machine => machine.location === this.state.currentLocation)

    return (
      <div className={classNames('page startRide', { 'shrink': !isVisible })}>
        {
          !hideWaiver &&
          <div className="waiverText">
            <h2>Waiver Not Signed</h2>
            <p>Please have your rider sign the Participant Release & Liability Waiver before continuing with the AllCore360˚ ride.</p>
            <a href={waiverLink}>
              <Button variant="contained" style={theme.button.primary} className='button'>
                Sign Waiver
              </Button>
            </a>
          </div>
        }

        <h2>Configure AllCore360˚ Ride</h2>

        <div className="rideDetails">
          <div className="flexRow">
            {
              locations && locations.length > 0 &&
                <div className="rideDetailSelectWrapper">
                  <FormControl>
                    <InputLabel shrink htmlFor="locationSelect">Location</InputLabel>
                    <Select
                      className="rideDetailSelect"
                      id="locationSelect"
                      value={currentLocation}
                      name="Location"
                      onChange={this.changeLocation}>
                      {
                        this.props.locations && this.props.locations.map((location, index) => {
                          return (
                            <MenuItem key={location._id} value={location._id}>{location.title}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
            }
            <div className="rideDetailSelectWrapper">
              <FormControl>
                <InputLabel shrink htmlFor="rideTypeSelect">Ride Type</InputLabel>
                <Select
                  className="rideDetailSelect"
                  id="rideTypeSelect"
                  value={rideType}
                  name="Ride Type"
                  onChange={this.changeRideType}>
                  { currentPatient.lastRide && <MenuItem value={'lastRide'}>Last Ride</MenuItem> }
                  <MenuItem value={'custom'}>Custom Ride</MenuItem>
                  <MenuItem value={'template'}>Ride Template</MenuItem>
                  <MenuItem value={'evaluation'}>Core Evaluation</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="rideDetailSelectWrapper">
              <FormControl>
                <InputLabel shrink htmlFor="machineSelect">Machine</InputLabel>
                <Select
                  className="machineSelect"
                  id="machineSelect"
                  disabled={machines.length === 0}
                  value={this.state.currentMachine}
                  name="Machine"
                  onChange={this.changeMachine}>
                  <MenuItem value=""></MenuItem>
                  {
                    machines.map(machine => (
                      <MenuItem key={machine._id} value={machine._id}>{machine.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            {
              rideType === 'template' &&
                <div className="averageAngle">
                  <label>Average Angle</label>
                  <ParameterInput
                    value={averageAngle}
                    onChange={value => this.setAverageAngle(value)}
                    min={0}
                    max={90}
                  />
                </div>
            }
          </div>
        </div>
        {
          (rideType === 'lastRide' || rideType === 'custom') &&
            <RideConfig
              isVisible={isVisible}
              projectedCoreScore={projectedCoreScore}
              personalBest={personalBest}
              ride={this.state.ride}
              onChange={this.handleRideConfig}
              currentPatient={this.props.currentPatient}
              goHome={this.props.goHome}
              submitRide={this.submitRide}
            />
        }
        {
          rideType === 'evaluation' &&
            <CoreEvaluationLayout
              isVisible={isVisible}
              currentLocation={currentLocation}
              currentMachine={currentMachine}
              goHome={this.props.goHome}
              afterEvaluation={this.afterEvaluation}
            />
        }
        {
          rideType === 'template' &&
            <RideTemplateSelect
              isVisible={isVisible}
              averageAngle={baseAngle}
              selectTemplate={this.selectTemplate}
            />
        }
      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(StartRide)
