import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import Button from '@material-ui/core/Button'
import Portal from '@material-ui/core/Portal'

import { connect } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'

import { getPatientAssessments, savePatientAssessment } from '../actions'
import PatientNotesHistory from './PatientNotesHistory'

import '../styles/patientNotes.scss'

const styles = theme => (null)

const mapStateToProps = ({ currentUser, currentPatient }, ownProps) => {
  let { assessmentResults = [] } = currentPatient

  assessmentResults.filter((result) => {
    return result._bundle === 'note'
  }).reverse()

  return {
    currentUser,
    currentPatient,
    assessmentResults,
  }
}

const mapDispatchToProps = {
  savePatientAssessment,
  getPatientAssessments
}

class PatientNotes extends React.Component {
  state = {
    newNote: '',
    confirm: false,
  }

  handleNoteChange = (event) => {
    this.setState({ newNote: event.target.value })
  }

  async submitAssessment () {
    let assessment = {
      _bundle: 'note',
      patient: this.props.currentPatient._id,
      user: this.props.currentUser._id,
      group: this.props.currentUser.groups[0]._id,
      value: this.state.newNote,
      created: moment()
    }

    await this.props.savePatientAssessment(assessment, 'Note', this.props.currentPatient)
    await this.props.getPatientAssessments(this.props.currentPatient._id)
    this.setState({ confirm: false, newNote: '' })
  }

  render () {
    const { theme } = this.props

    return (

      <div className="page subPage">

        <div className="inset">

          <h2>Record Note</h2>
          <h3>{moment().format('MMMM Do, YYYY')}</h3>

          <TextField
            label={`Note for ${this.props.currentPatient.name.given} ${this.props.currentPatient.name.family}`}
            multiline
            rows={5}
            classes={{ root: 'newNote' }}
            value={this.state.newNote}
            onChange={this.handleNoteChange}
            variant="outlined"
          />

          <footer className="flexRow">
            <Button onClick={() => this.setState({ confirm: true })} type="submit" variant="contained" style={theme.button.primary} className="button">Save</Button>
          </footer>
        </div>

        <h2>Note History</h2>
        <PatientNotesHistory type="note" assessmentResults={this.props.assessmentResults} />

        {
          this.state.confirm &&
          <Portal container={document.body}>
            <div className="modal confirm" style={{ backgroundColor: theme.palette.primary.fade }}>

              <p style={{ marginBottom: 20, maxWidth: 600 }}>Are you sure you want to record this note for {this.props.currentPatient.name.given} {this.props.currentPatient.name.family}?</p>

              <div className="noteConfirm">
                <TextField
                  multiline
                  spellCheck="false"
                  classes={{ root: 'newNote' }}
                  value={this.state.newNote}
                  variant="outlined"
                />
              </div>

              <footer className="flexRow">
                <Button onClick={() => this.setState({ confirm: false })} variant="contained" style={{ border: '2px solid white', color: 'white', backgroundColor: 'rgba(0,0,0,0)' }} className='button'>Cancel</Button>
                <Button onClick={() => this.submitAssessment()} type="submit" variant="contained" style={theme.button.primary} className="button">Save</Button>
              </footer>

            </div>
          </Portal>
        }

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientNotes)
