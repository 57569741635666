import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NumericInput from './NumericInput'
import classNames from 'classnames'
import uniqueId from 'lodash/uniqueId'

import './index.scss'

class Stepper extends Component {
  adjustParameter () {
    let value = this.props.value + this.props.direction
    this.props.onChange(value)
  }

  handleHold () {
    this.interval = setInterval(() => this.adjustParameter(), 150)
  }

  handleRelease () {
    clearInterval(this.interval)
  }

  render () {
    let props = {
      className: classNames(
        'stepper',
        { 'more': this.props.direction > 0 },
        { 'less': this.props.direction < 0 },
        { 'disabled': this.props.disabled },
      )
    }
    if (!this.props.disabled) {
      props = {
        ...props,
        onClick: () => this.adjustParameter(),
        onTouchStart: () => this.handleHold(),
        onMouseDown: () => this.handleHold(),
        onTouchEnd: () => this.handleRelease(),
        onMouseUp: () => this.handleRelease(),
        onMouseOut: () => this.handleRelease(),
      }
    }
    return React.createElement('div', props)
  }
}

class ParameterInput extends Component {
  componentDidMount () {
    this.id = uniqueId('parameter-input-')
  }

  handleChange (value) {
    // @todo convert value to a number
    if (!Number.isNaN(this.props.min)) {
      value = Math.max(value, this.props.min)
    }
    if (!Number.isNaN(this.props.max)) {
      value = Math.min(value, this.props.max)
    }
    this.props.onChange(value)
  }

  render () {
    return React.createElement('div', {
      className: classNames('parameter', 'parameter-input', this.props.color, { 'disabled': this.props.disabled }),
    }, [
      React.createElement(Stepper, {
        key: `${this.id}-stepper-less`,
        value: this.props.value,
        onChange: (value) => this.handleChange(value),
        disabled: this.props.disabled,
        direction: -1,
      }),
      React.createElement(NumericInput, {
        key: `${this.id}-input`,
        id: `${this.id}-input`,
        value: this.props.value,
        onChange: (value) => this.handleChange(value),
        disabled: this.props.disabled,
      }),
      React.createElement(Stepper, {
        key: `${this.id}-stepper-more`,
        value: this.props.value,
        onChange: (value) => this.handleChange(value),
        disabled: this.props.disabled,
        direction: 1,
      }),
      React.createElement('label', {
        key: `${this.id}-label`,
        htmlFor: `${this.id}-input`,
      }, [ this.props.label ])
    ])
  }
}

Stepper.propTypes = {
  value: PropTypes.number,
  direction: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

ParameterInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  color: PropTypes.string
}

export default ParameterInput
