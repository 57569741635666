const theme = {
  palette: {
    primary: {
      main: '#19324a',
      fade: 'rgba(25,50,74,.95)',
      700: '#77ba3e'
    },
    secondary: {
      main: 'rgba(119, 186, 62, 1)',
      light: 'rgba(119, 186, 62,.15)',
    },
  },
  typography: {
    fontFamily: ['Cairo', 'sans-serif'],
    useNextVariants: true,
  },
  button: {
    primary: {
      borderRadius: '100px',
      fontWeight: 'bold',
      fontFamily: ['Cairo', 'sans-serif'],
      border: '2px solid #77ba3e',
      backgroundColor: '#77ba3e',
      padding: '6px 10px',
      fontSize: '18px',
      color: 'white',
      textTransform: 'none',
      boxShadow: 'none'
    },
    secondary: {
      fontFamily: ['Cairo', 'sans-serif'],
      border: '2px solid #77ba3e',
      background: 'none',
      color: '#77ba3e'
    },
    white: {
      fontFamily: ['Cairo', 'sans-serif'],
      border: '2px solid white',
      background: 'none',
      color: 'white'
    },
    danger: {
      fontFamily: ['Cairo', 'sans-serif'],
      border: '2px solid #c54040',
      backgroundColor: '#c54040',
      color: 'white'
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#77ba3e',
        },
      },
    },
  }
}

export default theme
