import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { withStyles } from '@material-ui/core/styles'

import '../styles/index.scss'
import '../styles/patients.scss'

const styles = theme => (null)

const mapStateToProps = ({ currentLegal }) => ({ currentLegal })

class SettingsLegal extends React.Component {
  render () {
    const { currentLegal } = this.props
    return (
      <div className="page legal">
        <h2>AllCore360˚ Terms & Conditions</h2>
        <div dangerouslySetInnerHTML={{ __html: currentLegal.body }} />
      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps))(SettingsLegal)
