import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'

import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import MaskedInput from 'react-text-mask'

import TextField from '@material-ui/core/TextField'
import '../styles/rideConfig.scss'

import moment from 'moment'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  summary: {
    margin: 0
  },
  heading: {
    fontSize: 18,
    color: theme.palette.primary.main,
    flex: 1,
    alignItems: 'center'
  },
})

class ListExpandableItemCoreEval extends React.Component {
  render () {
    const { theme, locations, classes, index, session } = this.props
    const { editing, editingItem, confirmDelete, expanded, menu } = this.props.state

    let times, directions, durations
    if (session.durations) {
      times = Object.values(session.durations)
      directions = Object.keys(session.durations)
      durations = directions.map((direction, index) => {
        return { direction: direction, duration: times[index] }
      })
    }

    return (
      <li key={index} className={'expandableItem allcore360'}>
        <ExpansionPanel classes={{ root: classes.root }} expanded={expanded === index} onChange={this.props.handleChange(index)}>
          <ExpansionPanelSummary className={classes.summary}>
            <Typography className={classes.heading} style={{ display: 'flex' }}>
              <i className={expanded === index ? 'caret down' : 'caret'} />
              <strong>{moment(session.date).format('MMMM Do, YYYY')}</strong>
              <span style={{ width: 60, marginRight: 20 }}> {moment(session.date).format('h:mm A')} </span>

              <span className="sessionTotals angle">
                <i className="icon icon-angle"/>
                <span className="label">Angle</span>
                <span className="total">{session.angle}˚</span>
              </span>

              <span className="sessionTotals eval">
                <span>Core Evaluation</span>
              </span>

              <span className="sessionTotals coreScore">
                <i className="icon eval"/>
                <span className="total" style={{ color: theme.palette.secondary.main }}>{session.score && session.score.toFixed(1)}</span>
              </span>

            </Typography>

          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 20 }}>

            <span onClick={() => this.props.expandMenu(index)} className={classNames('icon', 'listMenu', { 'show': expanded === index })} />
            <div className={classNames('listActions', { 'active': menu === index })}>
              <span className="icon remove" onClick={() => this.props.confirmDelete(index)}/>
              <span onClick={() => this.props.editItem(session, index)} className="icon edit"/>
            </div>

            {
              locations && locations.length > 0 &&
              <div className={classNames('locationSelectWrapper session', editing === index && 'editing')}>
                <Select
                  className="locationSelect"
                  value={session.location}
                  onChange={(event) => this.props.changeLocation(event)}>
                  {
                    locations && locations.map((location, index) => {
                      return (
                        <MenuItem key={index} value={location._id}>{location.title}</MenuItem>
                      )
                    })
                  }
                </Select>
              </div>
            }

            <div className="evaluationConfig">
              {
                editing === index &&
                <div className="flexRow dateEditWrapper">
                  <i className="icon calendar" style={{ marginRight: 10, position: 'relative', top: '5px' }}></i>
                  <TextField
                    type="datetime-local"
                    classes={{ root: 'form-group' }}
                    onChange={(event) => this.props.updateParameter('date', event)}
                    value={moment(editingItem.startTime).format('YYYY-MM-DDTHH:mm')}
                  />
                </div>
              }

              {
                editing === index &&
                <div style={{ marginTop: -10, marginRight: 10, marginBottom: 30, marginLeft: 10, display: 'block' }} className="angleSelectWrapper">
                  <i className="icon icon-angle"/>
                  <span style={{ color: theme.palette.primary.main, fontSize: 18, marginRight: 50 }}>Angle</span>
                  <Select
                    className="angleSelect"
                    value={editing === index ? editingItem.angle : session.angle}
                    onChange={(event) => {
                      this.props.updateParameter('evalAngle', event)
                    }}>
                    <MenuItem key={1} value={80}>80˚</MenuItem>
                    <MenuItem key={2} value={75}>75˚</MenuItem>
                    <MenuItem key={3} value={60}>60˚</MenuItem>
                    <MenuItem key={4} value={45}>45˚</MenuItem>
                    <MenuItem key={5} value={30}>30˚</MenuItem>
                    <MenuItem key={6} value={15}>15˚</MenuItem>
                    <MenuItem key={7} value={0}>0˚</MenuItem>
                  </Select>
                </div>
              }

              <div className="durations" style={{ color: theme.palette.primary.main }}>
                {
                  durations && durations.map((duration) => {
                    return (
                      <div key={duration.direction} className={classNames('durationResult', duration.direction)}>
                        <i className="icon eval"/>
                        <span className="name">{duration.direction}</span>
                        {
                          editing === index
                            ? <div>
                              <MaskedInput
                                style={{ color: theme.palette.primary.main }}
                                className="durationInput"
                                value={editingItem.editedDurations ? editingItem.editedDurations[duration.direction] : moment.utc(duration.duration * 1000).format('m:ss')}
                                mask={[/[0-2]/, ':', /[0-5]/, /[0-9]/]}
                                onChange={(event) => this.props.updateCoreEvalDuration(duration.direction, event)}
                                showMask
                              />
                            </div>
                            : <span>{moment.utc(duration.duration * 1000).format('m:ss')}</span>
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>

            {
              editing === index &&
              <footer className="flexRow">
                <Button onClick={this.props.cancelEdit} variant="contained" style={theme.button.secondary} className='button'>Cancel</Button>
                <Button onClick={() => this.props.submitItem('evaluation')} variant="contained" style={this.props.theme.button.primary} className="button">Save Evaluation</Button>
              </footer>
            }
            {
              confirmDelete === index &&
            <div className="deleteModal">
              Are you sure you want to delete this core evaluation?
              <footer className="flexRow">
                <Button onClick={this.props.cancelDelete} variant="contained" style={theme.button.secondary} className='button'>Cancel</Button>
                <Button onClick={() => this.props.deleteItem(session, 'evaluation')} variant="contained" style={theme.button.danger} className="button">Delete Evaluation</Button>
              </footer>
            </div>
            }
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </li>

    )
  }
}

export default compose(withStyles(styles, { withTheme: true }))(ListExpandableItemCoreEval)
