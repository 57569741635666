import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRowLeaderboard from './TableRowLeaderboard'

import { getLeaderboard } from '../actions'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

import moment from 'moment'

const mapStateToProps = ({ leaderboard: { timePeriod, reportType, data } }, ownProps) => {
  let key = ownProps.location ? `${reportType}-${timePeriod}-${ownProps.group}-${ownProps.location}` : `${reportType}-${timePeriod}-${ownProps.group}`
  let leaderboard = data[key] || []

  return {
    timePeriod,
    reportType,
    leaderboard,
  }
}

const mapDispatchToProps = {
  getLeaderboard,
}

const styles = theme => (null)

class LeaderboardSingle extends React.Component {
  state = {
    leaderboard: []
  }

  componentDidMount () {
    this.getLeaderboard()
  }

  componentDidUpdate (prevProps) {
    // when the timePeriod/reportType changes, get new data
    if (prevProps.timePeriod !== this.props.timePeriod || prevProps.reportType !== this.props.reportType) {
      this.getLeaderboard()
    }
  }

  async getLeaderboard () {
    let options = {
      reportType: this.props.reportType,
      timePeriod: this.props.timePeriod,
      groupId: this.props.group,
      locationId: this.props.location,
    }

    this.props.getLeaderboard(options)
  }

  render () {
    const { theme } = this.props

    return (
      <div>
        {
          this.props.leaderboard.length > 0
            ? <Table className="leaderboard">
              <TableBody>
                {
                  this.props.leaderboard.map((session, index) => {
                    let { patient } = session

                    if (patient) {
                      let patientName = patient.name.given + ' ' + patient.name.family.charAt(0).toUpperCase() + '.'

                      // @note this.props.reportType will change before our state does,
                      // so we can't rely on the props to know which style of row to show.
                      // we should create dedicated components for single vs total leaderboards
                      if (this.props.reportType === 'single') {
                        return (
                          <TableRowLeaderboard
                            key={index}
                            index={index}
                            patientName={patientName}
                            location={session.location && this.props.role === 'therapist' && session.location}
                            group={session.group && this.props.role === 'administrator' && session.group}
                            startTime={moment(session.date).format('M/D/YYYY')}
                            coreScore={session.coreScore}
                            count={session.count}
                            theme={theme}
                          />
                        )
                      }
                      else if (this.props.reportType === 'total') {
                        return (
                          <TableRowLeaderboard
                            key={index}
                            index={index}
                            patientName={patientName}
                            location={session.location && this.props.role === 'therapist' && session.location}
                            group={session.group && this.props.role === 'administrator' && session.group}
                            coreScore={session.coreScoreTotal}
                            theme={theme}
                          />
                        )
                      }
                    }
                    else {
                      return false
                    }
                    return false
                  })
                }
              </TableBody>
            </Table>
            : <p style={{ textAlign: 'center', marginTop: 20 }}>There were no rides during this time period.</p>
        }
      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(LeaderboardSingle)
