import React from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const styles = theme => ({
  tabRoot: {
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:focus': {
      color: theme.palette.primary.secondary,
    },
  },
  tabSelected: {
    color: 'white',
    backgroundColor: '#c8c8c8'
  },
})

class TabsDateRange extends React.Component {
  handleChange = (event, activeTab) => {
    this.props.action(activeTab)
  }

  render () {
    const { classes } = this.props

    let items
    if (this.props.weekFirst) {
      items = [
        {
          value: 'thisWeek',
          label: 'This Week',
        },
        {
          value: 'thisMonth',
          label: 'This Month',
        },
        {
          value: 'thisYear',
          label: 'This Year',
        },
        {
          value: 'allTime',
          label: 'All Time',
        },
      ]
    }
    else {
      items = [
        {
          value: 'thisMonth',
          label: 'This Month',
        },
        {
          value: 'thisQuarter',
          label: 'This Quarter',
        },
        {
          value: 'thisYear',
          label: 'This Year',
        },
        {
          value: 'dateRange',
          label: 'Date Range',
        },
      ]
    }

    return (
      <div className="tabsDateRange">
        <Tabs classes={{ root: 'tabs', indicator: 'indicator' }} value={this.props.activeTab} onChange={this.handleChange}>
          {
            items.map(item => (
              <Tab
                key={item.value}
                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                disableRipple
                label={item.label}
                value={item.value}
              />
            ))
          }
        </Tabs>
      </div>
    )
  }
}

TabsDateRange.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TabsDateRange)
