import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.currentUser,
  }
}

const styles = theme => (null)

class TableRowLeaderboard extends React.Component {
  state = {
    rank: this.props.index + 1,
    firstPlace: this.props.index === 0,
    startTime: this.props.startTime
  }

  componentDidMount () {
    let patientLocation, patientGroup
    let groups = this.props.currentUser.groups
    let locations = []

    if (groups) {
      this.props.currentUser.groups.forEach((group) => {
        group.locations.forEach((location) => {
          locations.push(location)
        })
      })

      if (this.props.location) {
        patientLocation = locations.find((location) => {
          return location._id === this.props.location
        })
      }
      if (patientLocation) this.setState({ locationLabel: patientLocation.title })

      if (this.props.group) {
        patientGroup = groups.find((group) => {
          return group._id === this.props.group
        })
      }
      if (patientGroup) this.setState({ groupLabel: patientGroup.title })
    }
  }

  render () {
    const { theme, count, index, coreScore, patientName } = this.props
    const { firstPlace, rank, startTime, locationLabel, groupLabel } = this.state

    return (
      <TableRow style={{ backgroundColor: firstPlace && theme.palette.secondary.main }}>

        <TableCell className="rank"> { rank } </TableCell>
        <TableCell className="name"> { patientName } </TableCell>

        <TableCell className="location">
          {
            this.state.locationLabel
              ? <span>{ locationLabel }</span>
              : <span>{ groupLabel }</span>
          }
        </TableCell>

        {
          startTime &&
          <TableCell className="startTime"> { startTime } </TableCell>
        }

        <TableCell className="score" style={{ color: !firstPlace && theme.palette.secondary.main }}>

          {
            count > 1 &&
            <span className="count"><span>x</span> { count } </span>
          }

          {
            index === 0
              ? <i className="icon trophy"/>
              : <i className="icon star"/>
          }

          {coreScore.toLocaleString()}

        </TableCell>
      </TableRow>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps))(TableRowLeaderboard)
