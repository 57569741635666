const validateRideConfig = (rideConfig) => {
  // validate sets
  const ajv = require('ajv')()
  const schema = require('../schemas/rideConfig')

  return ajv.validate(schema, rideConfig)
}

const calculateCoreScore = (rideConfig) => {
  const spinFactor = 20
  const setChangeFactor = 5

  let totalSpins = rideConfig.sets
    .map(set => set.spins)
    .reduce((acc, val) => acc + val, 0)

  if (totalSpins === 0) {
    return
  }

  let averageAngle = rideConfig.sets
    .map(set => set.degrees * set.spins / totalSpins)
    .reduce((acc, val) => acc + val, 0)

  let setChanges = rideConfig.sets.length

  totalSpins = Math.min(totalSpins, 10)
  setChanges = Math.min(setChanges, 10)

  let angleFactor = ((90 - averageAngle) / 90 * 450)

  let coreScore = Math.round(
    Math.min((totalSpins * spinFactor), 200) +
    Math.min(angleFactor, 450) +
    Math.min((setChanges * setChangeFactor), 50)
  )

  rideConfig.coreScore = coreScore
  return coreScore
}

const calculateCoreEvaluationScore = (coreEvaluation) => {
  let { durations } = coreEvaluation

  let strengthFactor = 0
  switch (coreEvaluation.angle) {
    case 0: {
      strengthFactor = 9
      break
    }
    case 15: {
      strengthFactor = 7
      break
    }
    case 30: {
      strengthFactor = 5
      break
    }
    case 45: {
      strengthFactor = 4
      break
    }
    case 60: {
      strengthFactor = 2
      break
    }
    case 75: {
      strengthFactor = 1.5
      break
    }
    case 80: {
      strengthFactor = 1
      break
    }
    default: {
      strengthFactor = 1
      break
    }
  }

  let sagitalBalance = Math.min(durations.left, durations.right) / Math.max(durations.left, durations.right)
  let coronalBalance = Math.min(durations.forward, durations.backward) / Math.max(durations.forward, durations.backward)

  let enduranceFactor = Object.values(durations).reduce((acc, val) => (acc + val), 0)
  let balanceFactor = (sagitalBalance + coronalBalance) / 2

  const evaluationConstant = 43.2

  let score = Number((strengthFactor * enduranceFactor * balanceFactor) / evaluationConstant).toFixed(1)

  coreEvaluation.score = score
  return score
}

module.exports = {
  validateRideConfig,
  calculateCoreScore,
  calculateCoreEvaluationScore,
}
