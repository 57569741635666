import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'

import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'

import moment from 'moment'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  summary: {
    margin: 0
  },
  heading: {
    fontSize: 18,
    color: theme.palette.primary.main,
    flex: 1,
    alignItems: 'center'
  },
})

class ListExpandableItemQuestionnaire extends React.Component {
  render () {
    const { classes, index, result, content, theme } = this.props
    const { editing, confirmDelete, expanded, menu } = this.props.state

    let possibleScore = Object.keys(result.scores).length * 5
    let totalScore = Object.values(result.scores).reduce((a, b) => a + b)
    let percentage = ((totalScore / possibleScore) * 100).toFixed(0)

    return (

      <li key={index} className={'expandableItem questionnaireItem'}>
        <ExpansionPanel classes={{ root: classes.root }} expanded={expanded === index} onChange={this.props.handleChange(index)}>
          <ExpansionPanelSummary className={classes.summary}>
            <Typography className={classes.heading}>
              <i className={expanded === index ? 'caret down' : 'caret'} />
              <strong>{moment(result.createdAt).format('MMMM Do, YYYY')}</strong>
              <span style={{ width: 60, marginRight: 20 }}> {moment(result.createdAt).format('h:mm A')} </span>

              <span className="summary">
                <span className="summaryItem">
                  <span className="label">Total Score</span>
                  <span style={{ color: theme.palette.secondary.main }}>{totalScore}</span>
                </span>
                <span className="summaryItem">
                  <span className="label">Percentage</span>
                  <span style={{ color: theme.palette.secondary.main }}>{percentage}</span>
                  <span style={{ color: theme.palette.secondary.main }} className="percentage">%</span>
                </span>
              </span>

            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 20 }}>

            {
              editing === result._id
                ? <div className="questionnaireContent editing">

                </div>
                : <div className="questionnaireContent">
                  <div className="column">
                    {
                      content.map((question, questionIndex) => {
                        if (questionIndex < 5) {
                          if (result.scores[questionIndex]) {
                            return (
                              <div key={questionIndex} className="questionItem">
                                <header>
                                  <span style={{ color: theme.palette.secondary.main }}>{result.scores[questionIndex]}</span>
                                  {question.name}
                                </header>
                                <p>{question.options[result.scores[questionIndex]]}</p>
                              </div>
                            )
                          }
                          else {
                            return (
                              <div key={questionIndex} className="questionItem empty">
                                <header>
                                  <span style={{ color: theme.palette.secondary.main }}>0</span>
                                  {question.name}
                                </header>
                              </div>
                            )
                          }
                        }
                        return true
                      })
                    }
                  </div>
                  <div className="column">
                    {
                      content.map((question, questionIndex) => {
                        if (questionIndex >= 5) {
                          if (result.scores[questionIndex]) {
                            return (
                              <div key={questionIndex} className="questionItem">
                                <header>
                                  <span style={{ color: theme.palette.secondary.main }}>{result.scores[questionIndex]}</span>
                                  {question.name}
                                </header>
                                <p>{question.options[result.scores[questionIndex]]}</p>
                              </div>
                            )
                          }
                          else {
                            return (
                              <div key={questionIndex} className="questionItem empty">
                                <header>
                                  <span style={{ color: theme.palette.secondary.main }}>0</span>
                                  {question.name}
                                </header>
                              </div>
                            )
                          }
                        }
                        return true
                      })
                    }
                  </div>
                </div>

            }

            <span onClick={() => this.props.expandMenu(index)} className={classNames('icon', 'listMenu', { 'show': expanded === index })} />
            <div className={classNames('listActions', { 'active': menu === index })}>
              <span className="icon remove" onClick={() => this.props.confirmDelete(index)}/>
            </div>
            {
              editing === result._id &&
                <footer className="flexRow">
                  <Button onClick={this.props.cancelEdit} variant="contained" style={theme.button.secondary} className='button'>Cancel</Button>
                  <Button onClick={() => this.props.submitItem('questionnaire', result)} variant="contained" style={theme.button.primary} className="button">Save Questionnaire</Button>
                </footer>
            }
            {
              confirmDelete === index &&
              <div className="deleteModal">
                Are you sure you want to delete this questionnaire?
                <footer className="flexRow">
                  <Button onClick={this.props.cancelDelete} variant="contained" style={theme.button.secondary} className='button'>Cancel</Button>
                  <Button onClick={() => this.props.deleteItem(result, 'assessment', 'Questionnaire')} variant="contained" style={theme.button.danger} className="button">Delete Questionnaire</Button>
                </footer>
              </div>
            }

          </ExpansionPanelDetails>
        </ExpansionPanel>
      </li>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }))(ListExpandableItemQuestionnaire)
