import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Portal from '@material-ui/core/Portal'

import NumericInput from 'common/components/NumericInput'

import { connect } from 'react-redux'
import compose from 'recompose/compose'
import moment from 'moment'

import { getPatientAssessments, savePatientAssessment, deletePatientAssessment } from '../actions'

import AssessmentChart from './AssessmentChart'
import PatientNotesHistory from './PatientNotesHistory'

import '../styles/patientNotes.scss'

const styles = theme => (null)

const mapStateToProps = ({ currentUser, currentPatient }, ownProps) => {
  let { assessmentResults = [] } = currentPatient

  assessmentResults.filter((result) => {
    return result._bundle === 'assessment'
  }).reverse()

  return {
    currentUser,
    currentPatient,
    assessmentResults,
  }
}

const mapDispatchToProps = {
  getPatientAssessments,
  savePatientAssessment,
  deletePatientAssessment
}

class PatientAssessmentLayout extends React.Component {
  state = {
    confirm: false,
    invalid: false,
    newAssessment: [
      { name: 'Balance', score: 1 },
      { name: 'Flexibility', score: 1 },
      { name: 'Pain', score: 1 },
      { name: 'Range of Motion', score: 1 },
      { name: 'Strength', score: 1 },
    ]
  }

  componentDidMount () {
    this.props.getPatientAssessments(this.props.currentPatient._id)
  }

  onChangeParameter = (value, name) => {
    let assessment = this.state.newAssessment
    let targetParameter = this.state.newAssessment.find((parameter) => {
      return parameter.name === name
    })

    targetParameter.score = Number(value)

    this.setState({ invalid: false, newAssessment: assessment })
  }

  confirm () {
    let scores = this.state.newAssessment.map(parameter => parameter.score)
    let assessmentIsValid = true

    scores.forEach((score) => {
      if (score < 1 || score > 10) {
        assessmentIsValid = false
      }
    })

    if (assessmentIsValid) {
      this.setState({ confirm: true })
    }
    else {
      this.setState({ invalid: true })
    }
  }
  async submitAssessment () {
    let scores = this.state.newAssessment.map(parameter => parameter.score)

    let assessment = {
      _bundle: 'assessment',
      patient: this.props.currentPatient._id,
      user: this.props.currentUser._id,
      group: this.props.currentUser.groups[0]._id,
      values: {
        balance: scores[0],
        flexibility: scores[1],
        pain: scores[2],
        rangeOfMotion: scores[3],
        strength: scores[4],
      },
      created: moment()
    }

    await this.props.savePatientAssessment(assessment, 'Assessment', this.props.currentPatient)
    await this.props.getPatientAssessments(this.props.currentPatient._id)

    this.setState({
      confirm: false,
      invalid: false,
      newAssessment: [
        { name: 'Balance', score: 1 },
        { name: 'Flexibility', score: 1 },
        { name: 'Pain', score: 1 },
        { name: 'Range of Motion', score: 1 },
        { name: 'Strength', score: 1 },
      ]
    })
  }

  render () {
    const { theme } = this.props

    return (
      <div className="page subPage">

        <div className="inset">
          <h2>Record Assessment</h2>
          <h3>{moment().format('MMMM Do, YYYY')}</h3>
          <div className="flexRow assessment">
            {
              this.state.newAssessment.map((parameter, index) => {
                return (
                  <div key={index} className="assessmentParameter">
                    <header>{parameter.name}</header>
                    <AssessmentChart score={parameter.score}/>
                    <div className="inputWrapper">
                      <NumericInput
                        color={theme.palette.primary.main}
                        onChange={(event) => this.onChangeParameter(event, parameter.name)}
                        value={parameter.score} />
                    </div>
                  </div>
                )
              })
            }
          </div>

          {
            this.state.invalid &&
              <p className="invalidAssessment">Assessment scores must be between 1 and 10.</p>
          }

          <footer className="flexRow">
            <Button onClick={() => this.confirm()} type="submit" variant="contained" style={theme.button.primary} className="button">Save</Button>
          </footer>
        </div>

        <h2>Assessment History</h2>

        <PatientNotesHistory type="assessment" assessmentResults={this.props.assessmentResults}/>

        {
          this.state.confirm &&
          <Portal container={document.body}>
            <div className="modal confirm" style={{ backgroundColor: theme.palette.primary.fade }}>

              <p style={{ marginBottom: 20, maxWidth: 600 }}>Are you sure you want to record this assessment for {this.props.currentPatient.name.given} {this.props.currentPatient.name.family}?</p>

              <div className="flexRow assessment">
                {
                  this.state.newAssessment.map((parameter, index) => {
                    return (
                      <div key={index} className="assessmentParameter">
                        <header>{parameter.name}</header>
                        <AssessmentChart score={parameter.score}/>
                        <div className="inputWrapper">
                          <NumericInput
                            color={theme.palette.primary.main}
                            onChange={(event) => this.onChangeParameter(event, parameter.name)}
                            value={parameter.score} />
                        </div>
                      </div>
                    )
                  })
                }
              </div>

              <footer className="flexRow">
                <Button onClick={() => this.setState({ confirm: false })} variant="contained" style={{ border: '2px solid white', color: 'white', backgroundColor: 'rgba(0,0,0,0)' }} className='button'>Cancel</Button>
                <Button onClick={() => this.submitAssessment()} type="submit" variant="contained" style={theme.button.primary} className="button">Save</Button>
              </footer>

            </div>
          </Portal>
        }

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps, mapDispatchToProps))(PatientAssessmentLayout)
