import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from 'redux-form-material-ui'
import { Button } from '@material-ui/core'

import Tooltip from '../components/Tooltip'

import classNames from 'classnames'

const LocationForm = (props) => {
  let { isEditable } = props

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="flexRow">
        <Field
          name="title"
          type="text"
          label="Name"
          component={TextField}
          classes={{ root: 'form-group' }}
          className={classNames({ 'disabled': !isEditable })}
          disabled={!isEditable}
        />
        <Field
          name="phone"
          type="phone"
          label="Phone"
          classes={{ root: 'form-group' }}
          component={TextField}
          className={classNames({ 'disabled': !isEditable })}
          disabled={!isEditable}
        />
        <Field
          name="address.thoroughfare"
          type="text"
          label="Address"
          style={{ width: 300 }}
          classes={{ root: 'form-group' }}
          component={TextField}
          className={classNames({ 'disabled': !isEditable })}
          disabled={!isEditable}
        />
      </div>
      <div className="flexRow">
        <Field
          name="address.locality"
          type="text"
          label="City"
          classes={{ root: 'form-group' }}
          component={TextField}
          className={classNames({ 'disabled': !isEditable })}
          disabled={!isEditable}
        />
        <Field
          name="address.administrative_area"
          type="text"
          label="State"
          classes={{ root: 'form-group' }}
          component={TextField}
          className={classNames({ 'disabled': !isEditable })}
          disabled={!isEditable}
        />
        <Field
          name="address.postal_code"
          type="tel"
          pattern="[0-9]*"
          label="ZIP"
          classes={{ root: 'form-group' }}
          component={TextField}
          className={classNames({ 'disabled': !isEditable })}
          disabled={!isEditable}
        />
      </div>
      <div className="flexRow">
        <Field
          name="schedulingUrl"
          type="text"
          label="Schedule Link"
          component={TextField}
          classes={{ root: 'form-group half-width' }}
          className={classNames({ 'disabled': !isEditable })}
          disabled={!isEditable}
        />
        {
          isEditable &&
          <Tooltip>
            This link will appear in the summary email that is sent to riders after an AllCore360˚ ride and will encourage the rider to schedule their next ride.
          </Tooltip>
        }
      </div>
      { isEditable &&
        <footer className="flexRow">
          <Button onClick={props.onCancel} variant="contained" style={props.theme.button.secondary} className='button'>Cancel</Button>
          <Button type="submit" variant="contained" style={props.theme.button.primary} className="button">Save Location</Button>
        </footer>
      }
    </form>
  )
}

export default compose(connect(), reduxForm({ form: 'location' }), withStyles(null, { withTheme: true }))(LocationForm)
