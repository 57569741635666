import React from 'react'

class Tooltip extends React.Component {
  render () {
    return (
      <div className="tooltip">
        <span className="icon" /> What's this?
        <span className="tooltipText">
          { this.props.children }
        </span>
      </div>
    )
  }
}

export default Tooltip
