import app from '../client/app'

/**
 * Authenticate user
 */
export function authenticate () {
  return async dispatch => {
    try {
      let token = await app.authenticate()
      // get the payload
      let payload = await app.passport.verifyJWT(token.accessToken)
      // get the user
      let user = await dispatch(getUser(payload.userId))
      dispatch({ type: 'USER_IS_LOGGED_IN', user })
    }
    catch (e) {
      // delete token and cookie
      app.logout()
      dispatch({ type: 'USER_IS_ANONYMOUS' })
    }
  }
}

/**
 * Logout user
 */
export function logout () {
  return dispatch => {
    app.logout()
    dispatch({ type: 'USER_IS_ANONYMOUS' })
  }
}

/**
 * Get a fully-loaded user object, including the groups and locations the user belongs to.
 *
 * @param {number} userId The user id
 * @return {object} The user object
 */
export function getUser (userId) {
  return async () => {
    // get the user
    let user = await app.service('user').get(userId)

    // get user groups
    let ids = user.memberships.map(membership => membership.group)
    let { data: groups = [] } = await app.service('group').find({ query: { _id: { $in: ids } } })
    user.groups = groups

    // get user locations
    for (let group of groups) {
      let { data: locations = [] } = await app.service('location').find({ query: { group: group._id } })
      group.locations = locations
    }
    return user
  }
}

/**
 * Get the current legal item to be accepted
 */
export function getLegal () {
  return async dispatch => {
    let currentLegal = await app.service('legal').find({ query: { isCurrent: true } })
    dispatch({ type: 'SET_CURRENT_LEGAL', data: currentLegal.data[0] })
  }
}

/**
 * Update the current user's accepted legal item
 */
export function acceptLegal (userId, legalId) {
  return async () => {
    await app.service('user').patch(userId, { legal: legalId })
  }
}

/**
 * Trigger snackbar notification
 */
export function showSnack (message, action) {
  return async dispatch => {
    dispatch({ type: 'NEW_MESSAGE', message, action })
  }
}

/**
 * Dismiss snackbar
 */
export function dismissSnack () {
  return async dispatch => {
    dispatch({ type: 'DISMISS_MESSAGE' })
  }
}
