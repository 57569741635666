import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'

import { Link } from 'react-router-dom'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Navbar from './Navbar'

import compose from 'recompose/compose'

import '../styles/index.scss'
import '../styles/reports.scss'

const styles = theme => (null)

class ReportsLayout extends React.Component {
  state = {
    page: 0
  }

  render () {
    const { theme } = this.props

    let userAgent
    if (navigator.userAgent.indexOf('Firefox') >= 0) {
      userAgent = 'firefox'
    }

    return (
      <div className="page reportSelection">
        <AppBar position="fixed" className="appBar">
          <Navbar title="Reports" />
          <Tabs
            variant="fullWidth"
            value={this.state.page}
            classes={{ root: 'navTabs ' + userAgent, indicator: 'tabsIndicator' }}>
            <Tab disableRipple label="Select Report Type"/>
          </Tabs>
        </AppBar>

        <div className="reportSelect">

          <Link style={{ color: theme.palette.secondary.main }} to="reports/rides">
            <div className="flexRow">
              <i className="icon brand-blue"/> Ride Data
            </div>
            <p style={{ color: theme.palette.primary.main }}>View total number of rides and amount owed.</p>
          </Link>

          <Link style={{ color: theme.palette.secondary.main }} to="reports/leaderboards">
            <div className="flexRow">
              <i className="icon trophy"/> Core Score Leaderboard
            </div>
            <p style={{ color: theme.palette.primary.main }}>View top Core Score results.</p>
          </Link>

        </div>

      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }))(ReportsLayout)
