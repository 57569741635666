import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

export const withReactGA = (gaID) => (wrappedComponent) => {
  class ReactGAComponent extends Component {
    componentDidMount () {
      if (gaID) {
        ReactGA.initialize(gaID)
        this.props.history.listen((location) => {
          ReactGA.pageview(`${location.pathname}${location.search}`)
        })
      }
    }
    render () {
      return React.createElement(wrappedComponent)
    }
  }
  ReactGAComponent.propTypes = {
    history: PropTypes.object.isRequired
  }
  return ReactGAComponent
}
