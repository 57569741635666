import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'

import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'

import NumericInput from 'common/components/NumericInput'
import TextField from '@material-ui/core/TextField'
import '../styles/rideConfig.scss'

import AssessmentChart from './AssessmentChart'

import moment from 'moment'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  summary: {
    margin: 0
  },
  heading: {
    fontSize: 18,
    color: theme.palette.primary.main,
    flex: 1,
    alignItems: 'center'
  },
})

class ListExpandableItemAssessment extends React.Component {
  render () {
    const { classes, index, assessment, theme } = this.props
    const { editing, editingItem, confirmDelete, expanded, menu } = this.props.state

    return (

      <li key={index} className={'expandableItem assessmentItem'}>
        <ExpansionPanel classes={{ root: classes.root }} expanded={expanded === index} onChange={this.props.handleChange(index)}>
          <ExpansionPanelSummary className={classes.summary}>
            <Typography className={classes.heading}>
              <i className={expanded === index ? 'caret down' : 'caret'} />
              <strong>{moment(assessment.createdAt).format('MMMM Do, YYYY')}</strong>
              <span style={{ width: 60, marginRight: 20 }}> {moment(assessment.createdAt).format('h:mm A')} </span>

              <span className={expanded !== index ? 'assessmentResults' : 'assessmentResults expanded'}>
                <span className="result">
                    Balance
                  <span className="resultValue">
                    {assessment.values && assessment.values['balance']}
                  </span>
                </span>

                <span className="result">
                    Flexibility
                  <span className="resultValue">
                    {assessment.values && assessment.values['flexibility']}
                  </span>
                </span>

                <span className="result">
                    Pain
                  <span className="resultValue">
                    {assessment.values && assessment.values['pain']}
                  </span>
                </span>

                <span className="result">
                    R.O.M.
                  <span className="resultValue">
                    {assessment.values && assessment.values['rangeOfMotion']}
                  </span>
                </span>

                <span className="result">
                    Strength
                  <span className="resultValue">
                    {assessment.values && assessment.values['strength']}
                  </span>
                </span>

                <span className="result">
                  Strength
                  <span className="resultValue">
                    {assessment.values['strength']}
                  </span>
                </span>
              </span>

            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails style={{ padding: 20, flexDirection: 'column' }}>

            {
              editing === assessment._id &&
              <div className="flexRow dateEditWrapper">
                <i className="icon calendar" style={{ marginRight: 10, position: 'relative', top: '5px' }}></i>
                <TextField
                  type="datetime-local"
                  classes={{ root: 'form-group' }}
                  onChange={(event) => this.props.updateAssessment('date', event)}
                  value={moment(editingItem.date).format('YYYY-MM-DDTHH:mm')}
                />
              </div>
            }

            <div className={editing === assessment._id ? 'flexRow assessment editing' : 'flexRow assessment'}>
              <div className="assessmentParameter">
                <header>Balance</header>
                <AssessmentChart score={editing === assessment._id ? editingItem.values[0] : assessment.values['balance']}/>
                <div className="inputWrapper">
                  <NumericInput
                    disabled={editing !== assessment._id}
                    color={theme.palette.primary.main}
                    onChange={(event) => this.props.updateAssessment(0, event)}
                    value={editing === assessment._id ? editingItem.values[0] : assessment.values['balance']} />
                </div>
              </div>

              <div className="assessmentParameter">
                <header>Flexibility</header>
                <AssessmentChart score={editing === assessment._id ? editingItem.values[1] : assessment.values['flexibility']}/>
                <div className="inputWrapper">
                  <NumericInput
                    disabled={editing !== assessment._id}
                    color={theme.palette.primary.main}
                    onChange={(event) => this.props.updateAssessment(1, event)}
                    value={editing === assessment._id ? editingItem.values[1] : assessment.values['flexibility']} />
                </div>
              </div>

              <div className="assessmentParameter">
                <header>Pain</header>
                <AssessmentChart score={editing === assessment._id ? editingItem.values[2] : assessment.values['pain']} />
                <div className="inputWrapper">
                  <NumericInput
                    disabled={editing !== assessment._id}
                    color={theme.palette.primary.main}
                    onChange={(event) => this.props.updateAssessment(2, event)}
                    value={editing === assessment._id ? editingItem.values[2] : assessment.values['pain']} />
                </div>
              </div>

              <div className="assessmentParameter">
                <header>Range of Motion</header>
                <AssessmentChart score={editing === assessment._id ? editingItem.values[3] : assessment.values['rangeOfMotion']} />
                <div className="inputWrapper">
                  <NumericInput
                    disabled={editing !== assessment._id}
                    color={theme.palette.primary.main}
                    onChange={(event) => this.props.updateAssessment(3, event)}
                    value={editing === assessment._id ? editingItem.values[3] : assessment.values['rangeOfMotion']} />
                </div>
              </div>

              <div className="assessmentParameter">
                <header>Strength</header>
                <AssessmentChart score={editing === assessment._id ? editingItem.values[4] : assessment.values['strength']} />
                <div className="inputWrapper">
                  <NumericInput
                    disabled={editing !== assessment._id}
                    color={theme.palette.primary.main}
                    onChange={(event) => this.props.updateAssessment(4, event)}
                    value={editing === assessment._id ? editingItem.values[4] : assessment.values['strength']} />
                </div>
              </div>
            </div>
            <span onClick={() => this.props.expandMenu(index)} className={classNames('icon', 'listMenu', { 'show': expanded === index })} />
            <div className={classNames('listActions', { 'active': menu === index })}>
              <span className="icon remove" onClick={() => this.props.confirmDelete(index)}/>
              <span onClick={() => this.props.editAssessment('assessment', assessment)} className="icon edit"/>
            </div>
            {
              editing === assessment._id &&
                <footer className="flexRow">
                  <Button onClick={this.props.cancelEdit} variant="contained" style={this.props.theme.button.secondary} className='button'>Cancel</Button>
                  <Button onClick={() => this.props.submitItem('assessment', assessment)} variant="contained" style={theme.button.primary} className="button">Save Assessment</Button>
                </footer>
            }
            {
              confirmDelete === index &&
              <div className="deleteModal">
                Are you sure you want to delete this assessment?
                <footer className="flexRow">
                  <Button onClick={this.props.cancelDelete} variant="contained" style={this.props.theme.button.secondary} className='button'>Cancel</Button>
                  <Button onClick={() => this.props.deleteItem(assessment, 'assessment', 'Assessment')} variant="contained" style={theme.button.danger} className="button">Delete Assessment</Button>
                </footer>
              </div>
            }

          </ExpansionPanelDetails>
        </ExpansionPanel>
      </li>

    )
  }
}

export default compose(withStyles(styles, { withTheme: true }))(ListExpandableItemAssessment)
