import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { rideTemplates } from 'common/variables'
import { calculateCoreScore } from 'common/functions'

const styles = theme => (null)

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    currentPatient: state.currentPatient
  }
}

class RideTemplateSelect extends React.Component {
  render () {
    const { theme, averageAngle } = this.props

    return (
      <div className="rideTemplateSelect">
        <ul>
          {
            rideTemplates.map((template) => {
              let newTemplate = { ...template }
              newTemplate.sets.forEach(set => {
                set.degrees = Math.round(averageAngle * set.angleModifier + averageAngle)
              })
              let projectedCoreScore = calculateCoreScore({ sets: newTemplate.sets })
              if (averageAngle > template.maxAngle) {
                return null
              }
              return (
                <div className="rideTemplate" key={template.id} onClick={() => this.props.selectTemplate(newTemplate) }>
                  <div className="rideContent">
                    <div className="flexRow">
                      <div className="diagram" data-template={template.id} />
                      <header>
                        {template.name}
                        <span>{template.description}</span>
                      </header>
                      <div className="flexRow coreScoreWrapper">
                        <span className="score" style={{ color: theme.palette.secondary.main }}>
                          <i className="icon star"/>
                          <div>{projectedCoreScore}</div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </ul>
      </div>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }), connect(mapStateToProps))(RideTemplateSelect)
