import React from 'react'
import { ReportsIcon, UsersIcon, SettingsIcon } from './Icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

let barHeight = window.outerWidth < 767 ? 60 : 100
let fontSize = window.outerWidth < 767 ? 14 : 18

const styles = theme => ({
  tabsRoot: {
    position: 'fixed',
    borderTop: '1px solid #e8e8e8',
    backgroundColor: 'white',
    bottom: 0,
    paddingBottom: 10,
    right: 0,
    left: 0,
    height: barHeight,
    zIndex: 10,
  },
  tabRoot: {
    textTransform: 'initial',
    cursor: 'pointer',
    color: '#999',
    transition: '.2s',
    paddingTop: 20,
    fontSize: fontSize,
    flex: 1,
    '&:hover': {
      color: '#888',
      opacity: 1,
    },
    '&$tabSelected': {
      color: theme.palette.secondary.main,
    },
    '&:focus': {
      color: theme.palette.primary.secondary,
    },
  },
  tabSelected: {},
})

class TabBar extends React.Component {
  state = {
    activeTab: 0
  }

  handleChange = (event, activeTab) => {
    this.setState({ activeTab })
  }

  componentDidMount () {
    if (window.location.href.indexOf('settings') > 0) {
      this.setState({ activeTab: 2 })
    }

    if (window.location.href.indexOf('reports') > 0) {
      this.setState({ activeTab: 1 })
    }
  }

  render () {
    const { classes } = this.props
    const { activeTab } = this.state

    return (
      <div className="tabBar">
        <Tabs
          centered={true}
          value={activeTab}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: 'tabsIndicator' }}
        >
          <Tab
            icon={<UsersIcon/>}
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Riders"
            component={Link}
            to={'/'}
          />

          <Tab
            icon={<ReportsIcon/>}
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Reports"
            component={Link}
            to={'/reports'}
          />

          <Tab
            icon={<SettingsIcon/>}
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Settings"
            component={Link}
            to={'/settings'}
          />
        </Tabs>

        <footer className="support">For support call (402) 310-6023</footer>

      </div>
    )
  }
}

TabBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TabBar)
