import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'

import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'

import TextField from '@material-ui/core/TextField'
import '../styles/rideConfig.scss'

import nl2br from 'nl2br'
import sanitizeHtml from 'sanitize-html'

import moment from 'moment'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  summary: {
    margin: 0
  },
  heading: {
    fontSize: 18,
    color: theme.palette.primary.main,
    flex: 1,
    alignItems: 'center'
  },
})

class ListExpandableItemNote extends React.Component {
  render () {
    const { classes, index, note } = this.props
    const { editing, editingItem, confirmDelete, expanded, menu } = this.props.state

    let noteValue = nl2br(sanitizeHtml(note.value))

    return (

      <li key={index} className={'expandableItem noteItem'}>
        <ExpansionPanel classes={{ root: classes.root }} expanded={expanded === index} onChange={this.props.handleChange(index)}>
          <ExpansionPanelSummary className={classes.summary}>
            <Typography className={classes.heading}>
              <i className={expanded === index ? 'caret down' : 'caret'} />
              <strong>{moment(note.createdAt).format('MMMM Do, YYYY')}</strong>
              <span style={{ width: 60, marginRight: 20 }}> {moment(note.createdAt).format('h:mm A')} </span>
            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 20 }}>
            {
              editing === note._id &&
              <div className="flexRow dateEditWrapper">
                <i className="icon calendar" style={{ marginRight: 10, position: 'relative', top: '5px' }}></i>
                <TextField
                  type="datetime-local"
                  classes={{ root: 'form-group' }}
                  onChange={(event) => this.props.updateAssessment('date', event)}
                  value={moment(editingItem.date).format('YYYY-MM-DDTHH:mm')}
                />
              </div>
            }

            {
              editing === note._id
                ? <div className="note editing">
                  <TextField
                    multiline
                    rows={5}
                    classes={{ root: 'noteInput' }}
                    value={editingItem.value}
                    onChange={(event) => this.props.updateAssessment(null, event)}
                    variant="outlined"
                  />
                </div>
                : <div className="note">
                  <p dangerouslySetInnerHTML={{ __html: noteValue }}></p>
                </div>

            }

            <span onClick={() => this.props.expandMenu(index)} className={classNames('icon', 'listMenu', { 'show': expanded === index })} />
            <div className={classNames('listActions', { 'active': menu === index })}>
              <span className="icon remove" onClick={() => this.props.confirmDelete(index)}/>
              <span onClick={() => this.props.editAssessment('note', note)} className="icon edit"/>
            </div>
            {
              editing === note._id &&
                <footer className="flexRow">
                  <Button onClick={this.props.cancelEdit} variant="contained" style={this.props.theme.button.secondary} className='button'>Cancel</Button>
                  <Button onClick={() => this.props.submitItem('note', note)} variant="contained" style={this.props.theme.button.primary} className="button">Save Note</Button>
                </footer>
            }
            {
              confirmDelete === index &&
              <div className="deleteModal">
                Are you sure you want to delete this note?
                <footer className="flexRow">
                  <Button onClick={this.props.cancelDelete} variant="contained" style={this.props.theme.button.secondary} className='button'>Cancel</Button>
                  <Button onClick={() => this.props.deleteItem(note, 'assessment', 'Note')} variant="contained" style={this.props.theme.button.danger} className="button">Delete Note</Button>
                </footer>
              </div>
            }

          </ExpansionPanelDetails>
        </ExpansionPanel>
      </li>
    )
  }
}

export default compose(withStyles(styles, { withTheme: true }))(ListExpandableItemNote)
