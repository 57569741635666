import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

// Components
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'

// Actions
import { expandPanel, collapsePanel } from '../actions'

import classNames from 'classnames'

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  summary: {
    margin: 0
  },
  heading: {
    fontSize: 18,
    color: theme.palette.primary.main,
    flex: 1,
    alignItems: 'center'
  },
})

/**
 * Maps the `isExpanded` property for this component.
 */
const mapStateToProps = ({ expansionPanels: { activePanel } }, { panelGroup, panelKey }) => {
  return {
    isExpanded: activePanel[panelGroup] === panelKey,
  }
}
/**
 * Maps the `expandPanel` and `collapsePanel` actions for this component.
 */
const mapDispatchToProps = { expandPanel, collapsePanel }

function expandableListItem (ListItem) {
  return class extends Component {
    /**
     * Expand or collapse this panel.
     */
    handleChange = () => {
      if (this.props.isExpanded) {
        this.props.collapsePanel(this.props.panelGroup, this.props.panelKey)
      }
      else {
        this.props.expandPanel(this.props.panelGroup, this.props.panelKey)
      }
    }

    render () {
      return (
        <li className={classNames('expandableItem', this.props.className)}>
          <ExpansionPanel classes={{ root: this.props.classes.root }} expanded={this.props.isExpanded} onChange={this.handleChange}>
            <ExpansionPanelSummary className={this.props.classes.summary}>
              <Typography className={this.props.classes.heading}>
                <i className={classNames('caret', { 'down': this.props.isExpanded })} />
                {this.props.title}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 20 }}>
              <ListItem handleChange={this.handleChange} {...this.props} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </li>
      )
    }
  }
}

expandableListItem.propTypes = {
  title: PropTypes.string,
  panelGroup: PropTypes.string.isRequired,
  panelKey: PropTypes.string.isRequired,
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  expandableListItem,
)
